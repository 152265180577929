#feature {
    background: #FFFFFF;
    padding: 8px 0;
}

#high-level-object {
    background: #FCFCFC;
    border-top: 1px solid #EEEEEE;
    padding: 8px 0;
}

#low-level-object {
    background: #FCFCFC;
    border-bottom: 1px solid #EEEEEE;
    border-top: 1px solid #EEEEEE;
    padding: 8px 0;
}

#feature>div,
#high-level-object>div,
#low-level-object>div {
    font-weight: 500;
    font-size: 14px;
    color: #616161;
    cursor: pointer;
    margin-right: 10px;
    line-height: 22px;
    transition: color .2s ease;
}

#low-level-object>div {
    color: #424242;
    font-size: 13px;
    font-weight: 500;
}

#feature>div {
    font-size: 16px;
    font-weight: 700;
    position: relative;
}


#feature .active {
    color: #1B998B;
    border-bottom: 2px solid #1B998B;
}


#high-level-object .active,
#low-level-object .active {
    color: #1B998B;
    border-bottom: 1px solid #1B998B;
}

@media (max-width: 991.98px) {

    #feature,
    #high-level-object,
    #low-level-object {
        padding: 6px 0;
    }

    #feature>div {
        font-size: 14px;
    }

    #high-level-object>div {
        font-size: 12px;
    }

    #low-level-object>div {
        font-size: 12px;
    }
}


@media (max-width: 767.98px) {

    #feature,
    #high-level-object,
    #low-level-object {
        padding: 6px 0;
    }

    #feature>div {
        font-size: 13px;
        line-height: 20px;
    }

    #high-level-object>div {
        font-size: 12px;
        line-height: 20px;
    }

    #low-level-object>div {
        font-size: 11px;
        line-height: 18px;
    }
}