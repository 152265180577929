#header {
  background: rgb(239, 243, 244);
}

#header > th {
  background: rgb(239, 243, 244);
  position: sticky;
  top: -2px;
  font-size: 13px;
}

.rowHover tr:nth-child(even) {
  background-color: transparent;
}

.rowHover tr > td {
  font-size: 13px;
}

.ticket__header {
  background: #ddf4f2;
  padding: 20px;
  border-radius: 7px;
  /* box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1); */
}

.header__topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__topbar span {
  background: #ffa41d;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 7px;
}

@media (max-width: 768px) {
  .header__topbar {
    flex-direction: column;
  }

  .header__topbar span {
    font-size: 12px;
    padding: 2px 5px;
  }

  .header__topbar > div {
    margin-bottom: 10px;
  }
}

.ticket__response__body,
.comments__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 40px;
  row-gap: 20px;
  margin-top: 20px;
}

.ticket__response__body .main__response {
  grid-column: 1 / 3;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.comments__container__body {
  grid-column: 1 / 3;
}

.main__responses__inner {
  padding: 20px;
}

.main__responses__inner .single__question {
  margin-bottom: 20px;
}

.options__container {
  display: flex;
  align-items: center;
  column-gap: 20px;
  pointer-events: none;
  flex-wrap: wrap;
}

.ticket__response__body .linked__response {
  grid-column: 3 / 4;
}

.linked__response {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  min-height: 0;
  overflow-y: auto;
}

.linked__response__inner {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.linked__response__inner > p {
  margin: 0;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.linked__response__item {
  background: #fff;
  border: 2px solid #1b998b;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.linked__response__item p {
  margin: 0;
  width: fit-content;
  padding-left: 8px;
}

.linked__response__item a {
  width: fit-content;
  margin-left: 8px;
  display: inline-block;
  color: #0066ff;
  border-bottom: 1px solid #0066ff;
}

.linked__response__item p span {
  font-weight: 600;
}

.linked__response__item p:first-child {
  display: inline-block;
  background: #ddf4f2;
  padding: 2px 8px;
  border-radius: 5px;
  font-weight: 600;
}

.comments__body__inner {
  padding-left: 20px;
  width: 100%;
  display: flex;
  gap: 20px;
}

.comments__container__body h5 {
  padding: 10px 20px 20px 20px;
  margin: 0;
}

.comments__container__body .user__avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #1b998b;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .comments__body__inner {
    gap: 10px;
    padding-left: 10px;
  }

  .comments__container__body h5 {
    padding: 10px;
  }

  .comments__container__body .user__avatar {
    width: 32px;
    height: 32px;
    font-size: 14px;
  }
}

.comments__container__body textarea {
  width: 100%;
  padding: 10px;
  resize: none;
  border: none;
  outline: none;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .ticket__response__body {
    grid-template-columns: 1fr;
  }

  .ticket__response__body .main__response {
    grid-column: 1 / 2;
  }

  .ticket__response__body .linked__response {
    grid-column: 1 / 2;
  }

  .comments__container {
    grid-template-columns: 1fr;
  }

  .comments__container__body {
    grid-column: 1 / 2;
  }
}
