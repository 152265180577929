.CardView {
  box-shadow: 0px 1px 10px rgba(84, 110, 122, 0.16),
    0 2px 3px rgba(84, 110, 122, 0.23);
  border: none;
}

.CardView .card-header {
  background-color: #ffffff;
  padding-bottom: 10px;
  padding-top: 8px;
}

.CardView .card-header .card-view-title {
  font-size: 15px;
  color: #071e3d;
  margin-top: 10px;
}

.CardView .card-header .response-counter {
  font-size: 10px;
  font-weight: 500;
  color: #071e3d;
}

.CardView .card-header .sparkline-legend {
  font-size: 10px;
  margin-bottom: 0;
  font-weight: 500;
}

.CardView .card-header .dropdown {
  top: 45%;
  left: 83%;
  width: 60px;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.14);
}
.CardView ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.CardView li {
  padding: 1px 12px;
  font-size: 13px;
}
.CardView li:hover {
  background-color: #1b998b;
  cursor: pointer;
}

.semicircle-gauge .value-text {
  display: none;
}
