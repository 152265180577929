.ReactMonthPicker .react-datepicker {
    font-size: .8rem;
    border: 1px solid #E0E0E0;
}

.ReactMonthPicker .react-datepicker__header {
    border-bottom: none;
    background-color: #F5F5F5;
}

.ReactMonthPicker .react-datepicker__input-container input {
    padding: 6px 4px;
    border-radius: 2px;
    border: 1px solid #BDBDBD;
    font-size: 11px;
    width: 68px;
}

.ReactMonthPicker .react-datepicker__month-wrapper {
    color:#212121;
    border-bottom: 1px solid #EEE;
    padding: 2px 0px;
}

.ReactMonthPicker .react-datepicker__month-wrapper:last-child {
    border: none;
}

.ReactMonthPicker .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    /* margin: 2px; */
    border-radius: 4px;
    padding: 2px 0;
}    