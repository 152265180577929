.DashCard {
    background-color: rgba(27, 153, 139, .9) !important;
    color: #FFF;
    height: 100%;
    border: none !important;
    border-radius: 4px !important;
}

.DashCard .DashCardContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.DashCard .DashCardContent .flex-item-left {
    flex-basis: 70%;
}

.DashCard .DashCardContent .flex-item-right {
    flex-basis: 30%;
    text-align: right;
    font-size: 36px;
    font-weight: 700;
    color: #083836;
    border: none;
    border-radius: 3px;
}

.DashCard-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
    color: #083836;
}

.DashCard-description {
    font-size: 12px;
    line-height: 16px;
    color: #FFF;
    margin-top: 5px;
    font-weight: 300;
    margin-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .DashCard-title {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
        color: #083836;
    }

    .DashCard-description {
        font-size: 11px;
        line-height: 11px;
        color: #FFF;
        margin-top: 5px;
        font-weight: 300;
        margin-bottom: 0;
    }

    .DashCard .DashCardContent .flex-item-right {
        font-size: 18px;
        font-weight: 700;
    }
}

@media (min-width: 1200px) and (max-width: 1366px) {
    .DashCard .DashCardContent .flex-item-right {
        font-size: 30px;
        font-weight: 700;
    }
}