.info-bar .info small {
    display: block;
    line-height:16px;
}

.info-bar .info .info-value {
    /* font-size: 12px; */
    font-weight: 700;
}

.info-bar .info .info-text {
    /* font-size: 10px; */
    font-weight: 700;
    text-transform: uppercase;
    color: #4b4848;
} 