.HeaderInfoCard {
    color: #071E3D;
    font-size: 14px;
    font-weight: 700; 
}

.HeaderInfoCard .header-info > span {
    color: #1B998B; 
    font-size: 20px;
    font-weight: 300;
    margin-right: 15px;
}