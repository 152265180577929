.ResponeCategory {
    color:#424242;
    font-size: 13px;
    padding: 15px 15px;
}

.ResponeCategory ul {
    list-style: none;
}

.ResponeCategory ul > li {
    border:none;
    padding:0;
}

.ResponeCategory ul > li:first-child {
    margin-bottom: 5px;
}

.ResponeCategory ul > li > span {
    font-weight: 700;
    color: #212121;
}