.border-right-cl-3,
.border-right-cl-2 {
    border-right: 1px solid #dee2e6;
}

@media (max-width: 575.98px) {
    .border-right-cl-3,
    .border-right-cl-2 {
        border-right: none;
    }
}

@media (min-width:576px) and (max-width: 767.98px) {
    .border-right-cl-3:nth-child(2n),
    .border-right-cl-2:nth-child(2n) {
        border-right: none;
    }
}

@media (min-width:768px) and (max-width: 991.98px) {
    .border-right-cl-3:nth-child(2n),
    .border-right-cl-2:nth-child(2n) {
        border-right: none;
    }
}

@media (min-width:992px) and (max-width: 1199.98px) {
    .border-right-cl-3:nth-child(3n) {
        border-right: none;
    }

    .border-right-cl-2:nth-child(4n) {
        border-right: none;
    }
}

@media (min-width: 1200px) {
    .border-right-cl-3:nth-child(4n) {
        border-right: none;
    }

    .border-right-cl-2:nth-child(6n) {
        border-right: none;
    }
}
