.C3-Donut .c3-chart-arcs-title {
    font-size: 11px;
    fill: #212121;
    font-weight: 700;
    dominant-baseline:middle;
}

.C3-Donut .c3-tooltip-container {
    background-color: #FFF;
    color: #212121;
    font-size: 13px;
    font-weight: 700;
    z-index: 1;
}

.C3-Donut .c3-legend-item {
    font-size: 11px;
    fill: #424242;
    font-weight: 700;
}