.MediaObject {
    list-style: none;
    margin:0;
    padding:0;
    text-align: left;
    font-size: 13px;
    color: #212121;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
}

.MediaObject li {
    padding: 5px 2px;
    line-height: 18px;
}

.MediaObject li > span.indicator {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 4px;
    margin-bottom: -2px;
    border-radius: 2px;
    border: 2px solid #2b2828;
}

.dangerBlock {
    background-color: #F27D7D;
}

.successBlock {
    background-color: #1B998B;
}