* { margin: 0; padding: 0; outline: 0; box-sizing: border-box; font-family: Roboto; }

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('./asset/background5.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: border-box;
}

.success, .fail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success h3, .fail h3 {
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 1rem;
}
