.DateRange h6 {
    font-size: 14px;
    font-weight: 700;
    color: #424242;
}


.DateRange .react-datepicker {
    font-size: .8rem;
    border: 1px solid #E0E0E0;
}

.DateRange .react-datepicker__header {
    border-bottom: none;
    background-color: #F5F5F5;
}

.DateRange .start-date .react-datepicker__input-container input {
    padding: 6px 6px 6px 3px;
    border-radius: 2px;
    border: 1px solid #BDBDBD;
    font-size: 11px;
    width: 80px;
    max-width: 80px;
}

.DateRange .end-date .react-datepicker__input-container input {
    padding: 6px 5px;
    border-radius: 2px;
    border: 1px solid #BDBDBD;
    font-size: 11px;
    width: 70px;
    max-width: 70px;
}

.DateRange .react-datepicker__close-icon {
    padding: 0px 2px 0px 0px;
}

.DateRange .react-datepicker__day {
    background-color: #EEE;
    border-radius: 2px;
}

.DateRange .react-datepicker__day--today {
    background-color: rgb(15, 209, 144); 
}

.DateRange .react-datepicker__day--selected {
    background-color: rgb(15, 209, 144); 
}

.DateRange .react-datepicker__year-select,
.DateRange .react-datepicker__month-select {
    border:none;
    padding:2px;
    border-radius: 2px;
    color: #424242;
    font-weight: 700;
}

.DateRange .react-datepicker__day--selected,  
.DateRange .react-datepicker__day--in-selecting-range,
.DateRange .react-datepicker__day--in-range,
.DateRange .react-datepicker__month-text--selected,
.DateRange .react-datepicker__month-text--in-selecting-range,
.DateRange .react-datepicker__month-text--in-range,
.DateRange .react-datepicker__quarter-text--selected,
.DateRange .react-datepicker__quarter-text--in-selecting-range,
.DateRange .react-datepicker__quarter-text--in-range {
    background-color: rgb(15, 209, 144);    
}