
/* Placeholder CSS */
.inputField[type=email]::placeholder    { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=text]::placeholder     { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=password]::placeholder { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=select]::placeholder   { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.textAreaField::placeholder             { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 


/* Input Field CSS */
.inputField[type=text]      { font-size: 13px !important; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=email]     { font-size: 13px !important; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=select]    { font-size: 13px !important; font-weight: 400; color: rgb(94, 105, 110); } 
.passwordCSS[type=text]     { border-radius: 20px 0 0 20px !important; height: calc((1.5em + 0.4rem) + 2px) !important; } 
.passwordCSS[type=password] { border-radius: 20px 0 0 20px !important; height: calc((1.5em + 0.4rem) + 2px) !important; } 
.textAreaField              { font-size: 13px !important; font-weight: 400; color: rgb(52, 58, 64); border-radius: 20px !important; height: 75px !important; } 
.inputField                 { font-size: 13px !important; font-weight: 400; color: rgb(52, 58, 64); border-radius: 20px !important; } 
select.inputField           { font-size: 13px !important; font-weight: 400; color: rgb(52, 58, 64); border-radius: 20px !important; padding: 0 .75rem !important; height: calc((1.5em + 0.3rem) + 2px) !important; } 


/* Phone Number CSS */
.react-tel-input { margin-left: 18px; }
.react-tel-input .form-control[type=tel]::placeholder { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.flag-dropdown { border-radius: 20px 0 0 20px !important; } 
.react-tel-input .form-control { width: 275px !important; border-radius: 20px !important; font-size: 13px; font-weight: 400; color: rgb(52, 58, 64); height: calc((1.5em + 0.3rem) + 2px); } 


/* Modal Body CSS */ 
/* #demo > .modal-content { width: 63% !important; }  */

@media screen and (max-width: 600px) { .modal-content { width: 63% !important; } } 
@media screen and (max-width: 500px) { .modal-content { width: 75% !important; } } 
@media screen and (max-width: 450px) { .modal-content { width: 80% !important; } } 
@media screen and (max-width: 400px) { .modal-content { width: 90% !important; } } 
@media screen and (max-width: 375px) { .modal-content { width: 92% !important; } } 
@media screen and (max-width: 365px) { .modal-content { width: 94% !important; } } 
@media screen and (max-width: 360px) { .modal-content { width: 95% !important; } } 
@media screen and (max-width: 350px) { .modal-content { width: 98% !important; } } 
@media screen and (max-width: 342px) { .modal-content { width: 100% !important; } } 

