.Gauge {
    position: relative;
    padding: 0px 5px;
}

.gauge-title {
    color:#424242;
    font-size: 14px;
    font-weight: 700;
    height:20px;
}

.Gauge .value {
    position: absolute;
    bottom: 22%;
    left: 50%;
    transform: translateX(-50%);
}

.Gauge .value h6, .Gauge .value p{
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: #424242;
    line-height: 1rem;
}

.Gauge .value h6 {
    font-size: 14px;
    color: #212121;
}

.Gauge .value .legened {
    font-size: 10px;
    line-height: 8px;
    color: #616161;
}