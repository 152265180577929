.C3-PieChart .c3-legend-item {
    font-size: 11px;
    fill: #424242;
    font-weight: 700;
}

.PieChartIcon {
    font-size: 13px;
    margin-top: -10px;
    color: #424242;
    font-weight: 500;
}