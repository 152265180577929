.NpsDescriptor {
    font-size: 13px;
    font-weight: 700;
    color:#424242;
}

.NpsDescriptor span {
    padding:0 30px 0 0;
}

.NpsDescriptor span:last-child {
    padding:0;
}