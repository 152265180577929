.CoreAnalytics .core-links {
	font-size: 15px;
	font-weight: 700;
	color: #1b998b;
	cursor: pointer;
}

.react-datepicker__input-container input {
	padding: 5px;
	width: 100px;
}
