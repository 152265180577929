.Filter .title {
    color: #424242;
    font-weight: 700;
    font-size: 14px;
    text-align: left;
}

.Filter .period {
    color: #616161;
    font-weight: 700;
    font-size: 12px;
    text-align: left;
}

.Filter .btnFilter {
    font-size: 10px;
    font-weight: 700;
    background-color: #0EAD69;
    border-radius: 2px;

}

.Filter select.form-control {
    color: #424242;
    font-size: 11px;
    font-weight: 700;
    width:75px;
    padding: 0 1px;
}

.Filter select.form-control .filterTypeOption {
    font-size: 12px;
}

@media (max-width: 576px) {
    .Filter .card-body {
        padding : 10px 8px;
    }
}

