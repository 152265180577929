@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);

/* Placeholder CSS */
.inputField[type=email]::-webkit-input-placeholder    { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); }
.inputField[type=email]::-moz-placeholder    { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); }
.inputField[type=email]::-ms-input-placeholder    { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); }
.inputField[type=email]::placeholder    { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=text]::-webkit-input-placeholder     { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=text]::-moz-placeholder     { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=text]::-ms-input-placeholder     { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=text]::placeholder     { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=password]::-webkit-input-placeholder { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=password]::-moz-placeholder { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=password]::-ms-input-placeholder { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=password]::placeholder { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=select]::-webkit-input-placeholder   { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=select]::-moz-placeholder   { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=select]::-ms-input-placeholder   { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=select]::placeholder   { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.textAreaField::-webkit-input-placeholder             { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.textAreaField::-moz-placeholder             { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.textAreaField::-ms-input-placeholder             { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.textAreaField::placeholder             { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 


/* Input Field CSS */
.inputField[type=text]      { font-size: 13px !important; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=email]     { font-size: 13px !important; font-weight: 400; color: rgb(94, 105, 110); } 
.inputField[type=select]    { font-size: 13px !important; font-weight: 400; color: rgb(94, 105, 110); } 
.passwordCSS[type=text]     { border-radius: 20px 0 0 20px !important; height: calc((1.5em + 0.4rem) + 2px) !important; } 
.passwordCSS[type=password] { border-radius: 20px 0 0 20px !important; height: calc((1.5em + 0.4rem) + 2px) !important; } 
.textAreaField              { font-size: 13px !important; font-weight: 400; color: rgb(52, 58, 64); border-radius: 20px !important; height: 75px !important; } 
.inputField                 { font-size: 13px !important; font-weight: 400; color: rgb(52, 58, 64); border-radius: 20px !important; } 
select.inputField           { font-size: 13px !important; font-weight: 400; color: rgb(52, 58, 64); border-radius: 20px !important; padding: 0 .75rem !important; height: calc((1.5em + 0.3rem) + 2px) !important; } 


/* Phone Number CSS */
.react-tel-input { margin-left: 18px; }
.react-tel-input .form-control[type=tel]::-webkit-input-placeholder { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); }
.react-tel-input .form-control[type=tel]::-moz-placeholder { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); }
.react-tel-input .form-control[type=tel]::-ms-input-placeholder { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); }
.react-tel-input .form-control[type=tel]::placeholder { font-size: 13px; font-weight: 400; color: rgb(94, 105, 110); } 
.flag-dropdown { border-radius: 20px 0 0 20px !important; } 
.react-tel-input .form-control { width: 275px !important; border-radius: 20px !important; font-size: 13px; font-weight: 400; color: rgb(52, 58, 64); height: calc((1.5em + 0.3rem) + 2px); } 


/* Modal Body CSS */ 
/* #demo > .modal-content { width: 63% !important; }  */

@media screen and (max-width: 600px) { .modal-content { width: 63% !important; } } 
@media screen and (max-width: 500px) { .modal-content { width: 75% !important; } } 
@media screen and (max-width: 450px) { .modal-content { width: 80% !important; } } 
@media screen and (max-width: 400px) { .modal-content { width: 90% !important; } } 
@media screen and (max-width: 375px) { .modal-content { width: 92% !important; } } 
@media screen and (max-width: 365px) { .modal-content { width: 94% !important; } } 
@media screen and (max-width: 360px) { .modal-content { width: 95% !important; } } 
@media screen and (max-width: 350px) { .modal-content { width: 98% !important; } } 
@media screen and (max-width: 342px) { .modal-content { width: 100% !important; } } 



.signupPassword[type=password] { 
  font-size: 16px; color: #0d0d0e; padding-top: 5px;
} 

.mobileCollapse { 
    padding-top: 1rem;
    /* padding-bottom: 1rem; */
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: -15px;
    margin-left: -15px; 
} 


.dropdown-toggle::after {
    /* position: relative;  */
    top: 3px;
}


.secondary-menu-link:hover {
    background: transparent!important;
}

.carousel-indicators li {
    background-color: #1B998B !important;
}

.carousel-caption h3 {
    color: #1B998B; 
    font-size: 32px;
    font-weight: 500;
    text-align: left;
    position: relative; 
    top: -250px; 
    left: -100px; 
} 

.carousel-caption p {
    width: 75%; 
    color: rgb(32, 32, 32); 
    font-size: 18px; 
    font-weight: 500; 
    text-align: left; 
    position: relative; 
    top: -230px; 
    left: -100px; 
} 

.sliderBtn { 
    background-color: #1B998B !important; border-color: #1B998B !important; 
    color: white !important; font-weight: 500; border-radius: 0% !important; position: relative !important; 
    bottom: 210px; left: 580px; margin: 5px; 
} 

.carousel-item .d-none { display: block !important; } 

.carouselTitleOnly { display: none !important; } 


@media only screen and (max-width: 1024px) { 
    .carousel-caption h3 { top: -130px; left: -100px; font-size: 28px; } 
    .carousel-caption p { top: -130px; left: -100px; font-size: 18px; } 
    .sliderBtn { bottom: 130px; left: 400px; }
} 


@media only screen and (max-width: 768px) { 
    .carousel-caption h3 { top: -50px; left: -80px; font-size: 22px; } 
    .carousel-caption p { top: -40px; left: -80px; font-size: 16px; } 
    .sliderBtn { bottom: 50px; left: 290px; }
} 


@media only screen and (max-width: 750px) { 
    .hideCarousel { display: none; } 
    .carouselTitleOnly { display: block !important; text-align: center; margin: 25px 0px;  } 
    .carouselTitleOnly > h6 { color: #1B998B; font-size: 25px !important; font-weight: 500;  } 
} 


/* @media only screen and (max-width: 425px) { 
    .carousel-caption h3 { top: -70px; left: -40px; font-size: 13px; width: 80% !important; } 
    .carousel-caption p { display: none !important; } 
    .sliderBtn { bottom: 60px; left: 20px; font-size: 11px !important; padding: 3px !important; border-radius: 0% !important; }
} 




@media only screen and (max-width: 320px) { 
    .carousel-caption h3 { top: -40px; left: -30px; font-size: 13px; width: 90% !important; } 
    .carousel-caption p { display: none !important; } 
    .sliderBtn { bottom: 35px; left: 15px; font-size: 11px !important; padding: 3px !important; border-radius: 0% !important; }
}  */


.boxStyle { margin: 0 auto; } 
/* .orderImg { order: 1 !important; }  */
.orderImg > div { text-align: center; } 
.orderImg > div > img { width: 75% !important; } 
/* .orderText { order: 2 !important; } */
.heading { font-size: 22px !important; font-weight: 500 !important; text-align: center !important; } 
.text { font-size: 16px !important; font-weight: 400 !important; text-align: justify !important; color: black !important;}

@media only screen and (max-width: 768px) { 
	.npsStyle { margin: 0;} 
	.orderImg > div > img { width: 95% !important; } 
} 


@media only screen and (max-width: 425px) { 
	/* .orderImg { order: 2 !important; }  */
	.orderImg > div { text-align: center; } 
	.orderImg > div > img { width: 65% !important; } 
	/* .orderText { order: 1 !important; }  */
	.heading { font-size: 18px !important; font-weight: 500 !important; } 
	.text { font-size: 13px !important; } 
	.freeBtn { float: right !important; margin-bottom: 15px; } 
} 


@media only screen and (max-width: 335px) { 
	.orderImg > div > img { width: 85% !important; } 
} 



.boxStyle { margin: 0 auto; } 
/* .orderImg { order: 1 !important; }  */
.orderImg > div { text-align: center; } 
.orderImg > div > img { width: 75% !important; } 
/* .orderText { order: 2 !important; } */
.heading { font-size: 22px !important; font-weight: 500 !important; text-align: center !important; } 
.text { font-size: 16px !important; font-weight: 400 !important; text-align: justify !important; color: black !important;}

@media only screen and (max-width: 768px) { 
	.npsStyle { margin: 0;} 
	.orderImg > div > img { width: 95% !important; } 
} 


@media only screen and (max-width: 425px) { 
	/* .orderImg { order: 2 !important; }  */
	.orderImg > div { text-align: center; } 
	.orderImg > div > img { width: 65% !important; } 
	/* .orderText { order: 1 !important; }  */
	.heading { font-size: 18px !important; font-weight: 500 !important; } 
	.text { font-size: 13px !important; } 
	.freeBtn { float: right !important; margin-bottom: 15px; } 
} 


@media only screen and (max-width: 335px) { 
	.orderImg > div > img { width: 85% !important; } 
} 



.boxStyle { margin: 0 auto;} 
/* .orderImg { order: 1 !important; }  */
.orderImg > div { text-align: center !important; } 
.orderImg > div > .employeeImg { width: 100% !important; } 
/* .orderText { order: 2 !important; }  */
.heading { font-size: 22px !important; font-weight: 500 !important; text-align: center !important; } 
.text { font-size: 16px !important; font-weight: 400 !important; text-align: justify !important; color: black !important;}

@media only screen and (max-width: 768px) { 
	.npsStyle { margin: 0;} 
	.orderImg > div > img { width: 95% !important; } 
} 


@media only screen and (max-width: 425px) { 
	.boxStyle { margin: 25px 0PX !important;} 
	/* .orderImg { order: 2 !important; }  */
	.orderImg > div { text-align: center; } 
	.orderImg > div > .employeeImg { width: 100% !important; } 
	/* .orderText { order: 1 !important; }  */
	.heading { font-size: 18px !important; font-weight: 500 !important; } 
	.text { font-size: 13px !important; } 
	.freeBtn { float: right !important; margin-bottom: 15px; } 
} 


@media only screen and (max-width: 335px) { 
	.orderImg > div > .employeeImg { width: 100% !important; } 
} 

.boxStyle { margin: 0 auto;} 
/* .orderImg { order: 1 !important; }  */
.orderImg > div { text-align: center !important; } 
.orderImg > div > .effortImg { width: 70% !important; } 
/* .orderText { order: 2 !important; }  */
.heading { font-size: 22px !important; font-weight: 500 !important; text-align: center !important; } 
.text { font-size: 16px !important; font-weight: 400 !important; text-align: justify !important; color: black !important;}

@media only screen and (max-width: 768px) { 
	.npsStyle { margin: 0;} 
	.orderImg > div > img { width: 95% !important; } 
} 


@media only screen and (max-width: 425px) { 
	.boxStyle { margin: 25px 0PX !important;} 
	/* .orderImg { order: 2 !important; }  */
	.orderImg > div { text-align: center; } 
	.orderImg > div > .effortImg { width: 45% !important; } 
	/* .orderText { order: 1 !important; }  */
	.heading { font-size: 18px !important; font-weight: 500 !important; } 
	.text { font-size: 13px !important; } 
	.freeBtn { float: right !important; margin-bottom: 15px; } 
} 


@media only screen and (max-width: 335px) { 
	.orderImg > div > .effortImg { width: 50% !important; } 
} 

.boxStyle { margin: 0 auto;} 
/* .orderImg { order: 1 !important; }  */
.orderImg > div { text-align: center; } 
.orderImg > div > img { width: 75% !important; } 
/* .orderText { order: 2 !important; } */
.heading { font-size: 22px !important; font-weight: 500 !important; text-align: center !important; } 
.text { font-size: 16px !important; font-weight: 400 !important; text-align: justify !important; color: black !important;}

@media only screen and (max-width: 768px) { 
	.npsStyle { margin: 0;} 
	.orderImg > div > img { width: 95% !important; } 
} 


@media only screen and (max-width: 425px) { 
	/* .orderImg { order: 2 !important; }  */
	.orderImg > div { text-align: center; } 
	.orderImg > div > img { width: 65% !important; } 
	/* .orderText { order: 1 !important; }  */
	.heading { font-size: 18px !important; font-weight: 500 !important; } 
	.text { font-size: 13px !important; } 
	.freeBtn { float: right !important; margin-bottom: 15px; } 
} 


@media only screen and (max-width: 335px) { 
	.orderImg > div > img { width: 85% !important; } 
} 


/*FOOTER*/

footer {
    /* background: #1e7a92 !important; */
    background: #1B998B;
    color: white;
    margin-top:100px;
}
  
.footer-li > li > a { color: #fff; font-size: 14px; }
  
footer a:hover { color: #FA944B; text-decoration: none; }
  
.copy { font-size: 12px; padding: 10px; border-top: 1px solid #FFFFFF; }
  
.footer-middle { padding-top: 2em; color: white; }

ul li a { color: white; font-size: 18px; }
ul li a:hover { color: white; }




.carousel-container-my-class > ul > li  { width: 150px !important;  } 
.carousel-container-my-class > ul > li > div  { text-align: center; width: 100%; } 
.carousel-container-my-class > ul > li > div > img  { text-align: center; } 
.carousel-container-my-class > ul > li > div > p  { text-align: center; } 


@media only screen and (max-width: 600px) { 
  .carousel-container-my-class > ul > li  { width: 80px !important; } 
} 

@media only screen and (max-width: 400px) { 
  .carousel-container-my-class > ul > li  { width: 60px !important; } 
} 




.guide-text { font-size: 22px !important; font-family: 'Roboto'; color: rgb(52, 58, 64); } 

.tags span{
	position: relative;
  float: left;
  height: 60px;
  width: 150px;
	margin-left: 10px;
	padding: 0 10px 0 12px;
	background: #1B998B;
  left: 10;
} 

.tags span:after { 
	content:"";
	float:left;
	position:absolute;
	top:0;
	right:-30px;
	width:0;
	height:0;
	border-color:transparent transparent transparent #1B998B;
	border-style:solid;
	border-width:30px 0 30px 30px;
	z-index: 99;		
} 
  
.tags span:before { 
	content:""; 
	float:left; 
	position:absolute; 
	top:0; 
	left:0; 
	width:0; 
	height:0; 
	border-color:transparent transparent transparent white; 
	border-style:solid; 
	border-width: 30px 0 30px 30px; 
} 

.tags span p { 
	font-size: 14px; 
	font-weight: 500; 
	color: white; 
	text-align: center; 
	margin-left: 30px; 
	margin-top: 18px;
	line-height: 1.8;
} 
.tags span p b { 
	color: rgb(214, 189, 47); 
	padding: 7px; 
	z-index: 999; 
	position: absolute; 
	top: 10px; 
	right: -15px; 
} 


.evenPart { 
	display: flex; 
	flex-direction: row; 
	justify-content: center; 
} 

@media only screen and (max-width: 900px) { 
	/* .arrowFlow { display: none; }  */ 
	.tags span { width: 100px; margin-left: 8px; height: 40px; } 
	.tags span p { font-size: 12.5px; margin-left: 15px; line-height: 0.5 } 
	.tags span:after { border-width: 20px 0 20px 20px; right: -20px;  } 
	.tags span:before { border-width: 20px 0 20px 20px; }  
	.flowIcon { font-size: 10px; } 
} 



@media only screen and (max-width: 600px) { 
	.arrowFlow { margin-left: -20px; } 
	.guide-text { margin-left: 20px; } 
	.tags span { width: 80px; margin-left: 8px; height: 30px; } 
	.tags span p { font-size: 11px; margin-left: 10px; line-height: 0.2; margin-top: 15px; } 
	.tags span:after { border-width: 15px 0 15px 15px; right: -15px;  } 
	.tags span:before { border-width: 15px 0 15px 15px; } 
	.flowIcon { display: none; } 
} 


@media only screen and (max-width: 425px) { 
	.arrowFlow { margin-left: -20px; } 
	.guide-text { margin-left: 20px; } 
	.tags span { width: 70px; margin-left: 4px; height: 30px; } 
	.tags span p { font-size: 10px; margin-left: 8px; line-height: 0.2; margin-top: 15px; z-index: 999; } 
	.tags span:after { border-width: 15px 0 15px 15px; right: -15px;  } 
	.tags span:before { border-width: 15px 0 15px 15px; } 
	.flowIcon { display: none; } 
} 



@media only screen and (max-width: 400px) { 
	/* .arrowFlow { display: none; }  */ 
	.tags span { width: 70px; margin-left: 4px; height: 30px; } 
	.tags span p { font-size: 10px; margin-left: 8px; line-height: 0.2; margin-top: 15px; z-index: 999; } 
	.tags span:after { border-width: 15px 0 15px 15px; right: -15px;  } 
	.tags span:before { border-width: 15px 0 15px 15px; } 
	.flowIcon { display: none; } 
} 



@media only screen and (max-width: 350px) { 
	/* .arrowFlow { display: none; }  */ 
	.tags span { width: 65px; margin-left: 4px; height: 30px; } 
	.tags span p { font-size: 10px; margin-left: 6px; line-height: 0.2; margin-top: 15px; z-index: 999; } 
	.tags span:after { border-width: 15px 0 15px 15px; right: -15px;  } 
	.tags span:before { border-width: 15px 0 15px 15px; } 
	.flowIcon { display: none; } 
} 





/* .tags span p {
  font-size: 9px;
  margin-top: 8px;
  margin-left: 4px;
}

.tags span {
  width: 50px;
  height: 30px;
} */ 



@media only screen and (max-width: 425px) { 
	/* .tags span{ width: 170px; margin-left: 12px; }  */
	/* .tags span p { font-size: 17px; margin-left: 30px; }  */
	.guide-text { font-size: 20px !important; }
	.card-title { font-size: 18px !important; }
	.card { min-height: 200px !important; } 
	.evenPart { flex-direction: column; }
} 


@media only screen and (max-width: 375px) { 
	.guide-text { font-size: 20px !important; } 
	.card-title { font-size: 18px !important; } 
	/* .tags span{ width: 150px; margin-left: 10px; } 
	.tags span p { font-size: 14px; margin-left: 26px; line-height: 1.5 !important; }  */
} 


@media only screen and (max-width: 360px) { 
	.guide-text { font-size: 19px !important; } 
	.card-title { font-size: 17px !important; } 
	/* .tags span { width: 140px; margin-left: 8px; }  */
} 


@media only screen and (max-width: 320px) { 
	.guide-text { font-size: 18px !important; } 
	.card-title { font-size: 16px !important; } 
	/* .tags span{ width: 130px; margin-left: 7px; } 
	.tags span p { font-size: 12.5px; margin-left: 26px; line-height: 1.5 !important; }  */
} 




.carousel-container > ul > li  { width: 200px !important; } 

.styleClients  { display: flex; justify-content: center; width: 100%; } 

.styleClients > div { width: 200px; height: 100px; display: flex; justify-content: center; } 

@media only screen and (max-width: 600px) { 
    .styleClients { display: flex; } 
    .styleClients > div { width: 120px; height: 70px;  } 
    .styleClients > div > img { margin-right: 0px !important; height: 40px; } 
} 



@media only screen and (max-width: 400px) { 
    .styleClients { display: flex; } 
    .styleClients > div { width: 110px; height: 55px;  } 
    .styleClients > div > img { margin-right: 0px !important; height: 35px; } 
} 


@media only screen and (max-width: 360px) { 
    .styleClients { display: flex; } 
    .styleClients > div { width: 100px; height: 50px;  } 
    .styleClients > div > img { margin-right: 0px !important; height: 30px; } 
} 


@media only screen and (max-width: 330px) { 
    .styleClients { display: flex; } 
    .styleClients > div { width: 90px; height: 45px;  } 
    .styleClients > div > img { margin-right: 0px !important; height: 30px; } 
} 







* { margin: 0; padding: 0; outline: 0; box-sizing: border-box; font-family: Roboto; } 

.wrapper { width: 100%; } 

.element { 
    z-index: 1000; display: flex; flex-direction: column; 
    justify-content: center; align-items: center; height: 100vh; 
    background: url(/static/media/background5.20ae1d24.png); 
    background-repeat: no-repeat; background-size: cover; background-origin: border-box; 
} 

.logo { margin: 0px 0px 10px 0px; } 

.box { 
    background: white; width: 370px; padding: 40px; border-radius: 15px;
    box-shadow: 0px 0px 5px 0px rgb(214, 214, 214);
} 

.box-header { margin-bottom: 20px; } 

.box > div > span { font-size: 20px; font-weight: bold; font-family: Roboto; } 
.box > div > span:nth-child(1) { color: #38454C; } 
.box > div > span:nth-child(2) { color: #1B998B; } 

.form-label { font-size: 16px; color: #667884; margin-bottom: 0.3rem; } 

input.form-control-signin { font-size: 15px; border: 1px solid #d5dadf; height: 30px; font-weight: 500; } 

#inputWithIcon { position: relative; } 
#inputWithIcon > img { cursor: pointer; position: absolute; margin-left: 265px; margin-top: 8px; } 

/* input[type=password]              { font-size: 16px; color: #000000; padding-top: 5px; }  */
/* input[type=password]::placeholder { font-size: 15px; color: #768192; font-weight: 400; position: relative; top: -9px; }  */
input[type=text]::-webkit-input-placeholder     { font-size: 16px; font-weight: 400; }
input[type=text]::-moz-placeholder     { font-size: 16px; font-weight: 400; }
input[type=text]::-ms-input-placeholder     { font-size: 16px; font-weight: 400; }
input[type=text]::placeholder     { font-size: 16px; font-weight: 400; } 
input[type=tel]::-webkit-input-placeholder      { font-size: 16px; font-weight: 400; } 
input[type=tel]::-moz-placeholder      { font-size: 16px; font-weight: 400; } 
input[type=tel]::-ms-input-placeholder      { font-size: 16px; font-weight: 400; } 
input[type=tel]::placeholder      { font-size: 16px; font-weight: 400; } 
input[type=select]::-webkit-input-placeholder   { font-size: 15px; font-weight: 400; } 
input[type=select]::-moz-placeholder   { font-size: 15px; font-weight: 400; } 
input[type=select]::-ms-input-placeholder   { font-size: 15px; font-weight: 400; } 
input[type=select]::placeholder   { font-size: 15px; font-weight: 400; } 

input[type=checkbox] { background: #ffffff !important; cursor: pointer; } 
.custom-control-label { font-size: 16px; color: #38454C; margin-bottom: 0.3rem; font-weight: 400; }  /* checkbox label */

button.btn-signin { 
    padding-top: 5px; padding-bottom: 5px; border-radius: 8px; 
    background: #1B998B; border: 0px; font-size: 16px; 
    font-weight: 500; color: #ffffff; 
} 
button.btn-signin:hover { color: #ffffff; } 

button.signInGoogle { 
    padding-top: 5px; padding-bottom: 5px; border-radius: 8px; 
    background: #FA541C; border: 0px; font-size: 16px; 
    font-weight: 500; color: white; 
} 
button.signInGoogle:hover { color: #ffffff; } 

.ifNo { font-size: 13px; color: #38454C; font-weight: bold; } 

.or { font-size: 13px; color: #667884; width: 20%; text-align: center; margin-top: 5px; font-weight: 400; }

.ifNo > span { color: #1B998B; cursor: pointer; } 





/* Media query */

@media screen and (max-width: 425px) { 
    .wrapper { width: 100%; margin: 0; padding: 0;} 
    .box { background: white; width: 370; padding: 30px; margin-top: 0px } 
    .logo { margin: -20px 0px 10px 0px; } 
    /* .element { background-image: none !important; }  */
} 


@media screen and (max-width: 375px) {  
    .box { background: white; width: 330px; padding: 30px; }  
    #inputWithIcon > img { margin-left: 245px; margin-top: 8px; } 

}  


@media screen and (max-width: 340px) {  
    .box { background: white; width: 310px; padding: 30px; }  
    #inputWithIcon > img { margin-left: 225px; margin-top: 8px; } 
}  


@media screen and (max-width: 319px) {  
    .box { background: white; width: 290px; padding: 20px; }  
    #inputWithIcon > img { margin-left: 235px; margin-top: 8px; } 
}  






.hidden {
    display:none;
    }

.visible {
    display:block;
    position:absolute;
    top:0;left:0;
    }
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  /* border-top: 3px solid #310D31; */
  border-bottom: 3px solid #083836;
  width: 80px;
  height: 80px;
  -webkit-animation: spin .5s linear infinite; /* Safari */
  animation: spin .5s linear infinite;

}
      
      
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
      
.flex-container {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  /*background-color: DodgerBlue;*/
  
}

.loading {
  color: #083836;
  font: 300 2em/150% Roboto;
  text-align: center;
  margin: 0;
}


/* loading dots */

.loading:after {
  content: ' .';
  -webkit-animation: dots 0.5s steps(5, end) infinite;
          animation: dots 0.5s steps(5, end) infinite;}

@-webkit-keyframes dots {
  0%, 20% {
    color: #083836;
    text-shadow:
      .25em 0 0 #083836,
      .5em 0 0 #083836}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 #083836,
      .5em 0 0 #083836;}
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 #083836}
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;}}

@keyframes dots {
  0%, 20% {
    color: #083836;
    text-shadow:
      .25em 0 0 #083836,
      .5em 0 0 #083836}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 #083836,
      .5em 0 0 #083836;}
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 #083836}
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;}}

.btnHover:hover { 
    background: #1B998B !important; 
    border-color: #1B998B !important;
}
#clickable { cursor: pointer; } 

#header { background: rgb(239, 243, 244); } 

#header > th { background: rgb(239, 243, 244); position: -webkit-sticky; position: sticky; top: -2px; font-size: 13px; } 

.rowHover tr:nth-child(even) { background-color: transparent; } 

.rowHover tr > td { font-size: 13px; } 


.smFooter > li { 
  cursor: pointer; 
  /* float: left;  */
  display: inline;
  margin-right: 20px;
  position: relative;
  /* margin-bottom: 0px !important; */
} 

.smFooter > li:after { 
  content: ''; width: 5px; height: 5px; 
  background: #fff; position: absolute; top: 3px;
  left: -8px; border-radius: 500px; 
} 


.btnHover:hover { 
    background: #1B998B !important; 
    border-color: #1B998B !important;
}
#clickable { cursor: pointer; } 

#header { background: rgb(239, 243, 244); } 

#header > th { background: rgb(239, 243, 244); position: -webkit-sticky; position: sticky; top: -2px; font-size: 13px; } 

.rowHover tr:nth-child(even) { background-color: transparent; } 

.rowHover tr > td { font-size: 13px; } 
.btnHover:hover { 
    background: #1B998B !important; 
    border-color: #1B998B !important;
}
#clickable { cursor: pointer; } 

#header { background: rgb(239, 243, 244); } 

#header > th { background: rgb(239, 243, 244); position: -webkit-sticky; position: sticky; top: -2px; font-size: 13px; } 

.rowHover tr:nth-child(even) { background-color: transparent; } 

.rowHover tr > td { font-size: 13px; } 
.btnHover:hover { 
    background: #1B998B !important; 
    border-color: #1B998B !important;
}

#clickable { cursor: pointer; } 

#header { background: rgb(239, 243, 244); } 

#header > th { background: rgb(239, 243, 244); position: -webkit-sticky; position: sticky; top: -2px; font-size: 13px; } 

.rowHover tr:nth-child(even) { background-color: transparent; } 

.rowHover tr > td { font-size: 13px; } 
@media only screen and (max-width: 425px){
  .react-select__control{
    font-size: 10px;
  }
  /* .react-select-container{
    width: 50% !important;
  } */
}
@media only screen and (max-width: 425px){
  .react-select__control{
    font-size: 10px;
  }
  /* .react-select-container{
    width: 50% !important;
  } */
}


@media only screen and (max-width: 425px){
  .react-select__control{
    font-size: 10px;
  }
  /* .react-select-container{
    width: 50% !important;
  } */
}
@media only screen and (max-width: 425px) {
    .react-select__control {
        font-size: 10px;
    }

    /* .react-select-container {
        width: 50% !important;
    } */
}
/* .Select.is-open { position: relative; z-index: 1000; } */
.react-select-container .react-select__value-container {
  padding: 2px 5px;
}
.react-select-container .react-select__control {
  border-radius: 3px;
  border: 1px solid #BDBDBD;
  font-size: 12px;
  font-weight: 500;
  color: #212121;
  text-transform: uppercase;
  -webkit-transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.react-select-container .react-select__menu {
  border-radius: 4px;
}

.react-select-container .react-select__menu .react-select__option {
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  color: #424242;
  text-transform: uppercase;
} 

.react-select-container .react-select__menu .react-select__option:hover {
  background-color: rgb(135, 197, 190);
}

.react-select-container .react-select__menu .react-select__option--is-selected {
  background-color: #1B998B;
  color: #FFF;
}

.react-select-container .react-select__control.react-select__control--is-focused {
  border-color: #1B998B !important;
  box-shadow: none;
}

.react-select-container .react-select__control.react-select__control--menu-is-open {
    /* border-color: red !important; */
    box-shadow: none;
}

@media only screen and (max-width: 425px) {
  .react-select__control {
    font-size: 10px;
  }

  /* .react-select-container {
    width: 50% !important;
  } */
}
@media only screen and (max-width: 425px){
  .react-select__control{
    font-size: 10px;
  }
  /* .react-select-container{
    width: 50% !important;
  } */
}
.requiredField:after {
    color: #F27D7D;
    content: "*";
}
.p-splitbutton .p-button {
  background-color: #1b998b !important;
  border-color: #1b998b !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.p-menu .p-menuitem-link {
  font-size: 12px;
}

.p-splitbutton .p-button:hover {
  background-color: #0b6157 !important;
  border-color: #0b6157 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-menu {
  max-height: 220px;
  overflow-y: auto;
}

.project-question-container {
    width: 50%;
    padding: 10px 25px 5px 25px;
    border-radius: 4px;
    background-color: #EFF3F4;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1024px) {
    .project-question-container {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
}

.btnDeleteHover:hover {
    background-color: #F2494C !important;
}

#clickable { cursor: pointer; } 

#header { background: rgb(239, 243, 244); } 

#header > th { background: rgb(239, 243, 244); position: -webkit-sticky; position: sticky; top: -2px; font-size: 13px; } 

.rowHover tr:nth-child(even) { background-color: transparent; } 

.rowHover tr > td { font-size: 13px; } 


@media only screen and (max-width: 425px){
  .react-select__control{
    font-size: 10px;
  }
  /* .react-select-container{
    width: 50% !important;
  } */
}
@media only screen and (max-width: 425px){
  .react-select__control{
    font-size: 10px;
  }
  /* .react-select-container{
    width: 50% !important;
  } */
}


@media only screen and (max-width: 425px){
  .react-select__control{
    font-size: 10px;
  }
  /* .react-select-container{
    width: 50% !important;
  } */
}
@media only screen and (max-width: 425px) {
    .react-select__control {
        font-size: 10px;
    }

    /* .react-select-container {
        width: 50% !important;
    } */
}
/* .Select.is-open { position: relative; z-index: 1000; } */
.react-select-container .react-select__value-container {
  padding: 2px 5px;
}
.react-select-container .react-select__control {
  border-radius: 3px;
  border: 1px solid #BDBDBD;
  font-size: 12px;
  font-weight: 500;
  color: #212121;
  text-transform: uppercase;
  -webkit-transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.react-select-container .react-select__menu {
  border-radius: 4px;
}

.react-select-container .react-select__menu .react-select__option {
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  color: #424242;
  text-transform: uppercase;
} 

.react-select-container .react-select__menu .react-select__option:hover {
  background-color: rgb(135, 197, 190);
}

.react-select-container .react-select__menu .react-select__option--is-selected {
  background-color: #1B998B;
  color: #FFF;
}

.react-select-container .react-select__control.react-select__control--is-focused {
  border-color: #1B998B !important;
  box-shadow: none;
}

.react-select-container .react-select__control.react-select__control--menu-is-open {
    /* border-color: red !important; */
    box-shadow: none;
}

@media only screen and (max-width: 425px) {
  .react-select__control {
    font-size: 10px;
  }

  /* .react-select-container {
    width: 50% !important;
  } */
}


@media only screen and (max-width: 425px){
  .react-select__control{
    font-size: 10px;
  }
  /* .react-select-container{
    width: 50% !important;
  } */
}
.p-splitbutton .p-button {
  background-color: #1b998b !important;
  border-color: #1b998b !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.p-menu .p-menuitem-link {
  font-size: 12px;
}

.p-splitbutton .p-button:hover {
  background-color: #0b6157 !important;
  border-color: #0b6157 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-menu {
  max-height: 220px;
  overflow-y: auto;
}

.btnDeleteHover:hover {
    background-color: #F2494C!important;
}

#clickable { cursor: pointer; } 

#header { background: rgb(239, 243, 244); } 

#header > th { background: rgb(239, 243, 244); position: -webkit-sticky; position: sticky; top: -2px; font-size: 13px; } 

.rowHover tr:nth-child(even) { background-color: transparent; } 

.rowHover tr > td { font-size: 13px; } 
.dislabel {
    width: 100%;
}
  
.inputname {
    border-radius: 0;
    border: 1px solid hsl(0,0%,80%);
    box-shadow: 1px 1px 5px 1px #D9D9D9;
    background: white;
    width: 100%;
    margin-bottom: 10px;
    padding: 2px 5px;
}

.dislabel {
    display: block;
}
input[type="checkbox"], .selectstatus {
    margin-left: 5px;
}
.dislabel {
    width: 100%;
}
  
.inputname {
    border-radius: 0;
    border: 1px solid hsl(0,0%,80%);
    box-shadow: 1px 1px 5px 1px #D9D9D9;
    background: white;
    width: 100%;
    margin-bottom: 10px;
    padding: 2px 5px;
}

.dislabel {
    display: block;
}
input[type="checkbox"], .selectstatus {
    margin-left: 5px;
}
.dislabel {
    width: 100%;
}
  
.inputname {
    border-radius: 0;
    border: 1px solid hsl(0,0%,80%);
    box-shadow: 1px 1px 5px 1px #D9D9D9;
    background: white;
    width: 100%;
    margin-bottom: 10px;
    padding: 2px 5px;
}

.dislabel {
    display: block;
}
input[type="checkbox"], .selectstatus {
    margin-left: 5px;
}
.dislabel {
    width: 100%;
}
  
.inputname {
    border-radius: 0;
    border: 1px solid hsl(0,0%,80%);
    box-shadow: 1px 1px 5px 1px #D9D9D9;
    background: white;
    width: 100%;
    margin-bottom: 10px;
    padding: 2px 5px;
}

.dislabel {
    display: block;
}
input[type="checkbox"], .selectstatus {
    margin-left: 5px;
}


@media only screen and (max-width: 425px){
  .react-select__control{
    font-size: 10px;
  }
  /* .react-select-container{
    width: 50% !important;
  } */
}
.channel-wrapper {
    padding: 10px; 
    margin: 10px;
    background: white;
    margin-bottom: 25px;
}

.channel-wrapper > div {
    width: 25%;
    float: left;
    box-shadow: 1px 1px 8px 1px grey;
    margin: 0;
    padding: 0;
    min-height: 100px;
    margin-right: 10px; 
}

.channel-wrapper h4 {
    margin: 0;
    padding: 10px;
    display: block !important;
    text-transform: capitalize;
    background-color: #310D31;
    color: #fff;
    cursor: pointer;
}

.dislabel {
    display: block;
}

.generate {
    display: block;
    outline: none;
    /* align-self: flex-start; */
    border: 0 solid grey;
    background-color: purple;
    text-decoration: none;
    color: white;
    position: relative;
    margin: 10px;
    padding: 5px 10px;
    top: 0;
    left: 0;
    z-index: 5;
}

.generate:hover {
    text-decoration: none;
    background-color: purple;
    color: white;
    box-shadow: 1px 1px 4px 1px grey; 
}

.generate:active { outline: none; }
p {
    text-align: left;
    text-align: initial;
}
.channel-wrapper {
    padding: 10px; 
    margin: 10px;
    background: white;
    margin-bottom: 25px;
}

.channel-wrapper > div {
    width: 25%;
    float: left;
    box-shadow: 1px 1px 8px 1px grey;
    margin: 0;
    padding: 0;
    min-height: 100px;
    margin-right: 10px; 
}

.channel-wrapper h4 {
    margin: 0;
    padding: 10px;
    display: block !important;
    text-transform: capitalize;
    background-color: #310D31;
    color: #fff;
    cursor: pointer;
}

.dislabel {
    display: block;
}

.generate {
    display: block;
    outline: none;
    /* align-self: flex-start; */
    border: 0 solid grey;
    background-color: purple;
    text-decoration: none;
    color: white;
    position: relative;
    margin: 10px;
    padding: 5px 10px;
    top: 0;
    left: 0;
    z-index: 5;
}

.generate:hover {
    text-decoration: none;
    background-color: purple;
    color: white;
    box-shadow: 1px 1px 4px 1px grey; 
}

.generate:active { outline: none; }
input.mcqres[type="radio"]:checked,
input.mcqres[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input.mcqres[type="radio"]:checked + label,
input.mcqres[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
input.mcqres[type="radio"]:checked + label:before,
input.mcqres[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgb(52, 58, 64);;
    border-radius: 100%;
    background: #fff;
}
input.mcqres[type="radio"]:checked + label:after,
input.mcqres[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 20px;
    height: 20px;
    background: rgb(27, 153, 139);
    border: 1px solid rgb(27, 153, 139);
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: 50px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
input.mcqres[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
input.mcqres[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

input.checkboxres[type="checkbox"]:checked,
input.checkboxres[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input.checkboxres[type="checkbox"]:checked + label,
input.checkboxres[type="checkbox"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
input.checkboxres[type="checkbox"]:checked + label:before,
input.checkboxres[type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgb(52, 58, 64);;
    /* border-radius: 100%; */
    background: #fff;
}
input.checkboxres[type="checkbox"]:checked + label:before {
    background: rgb(27, 153, 139);
}
input.checkboxres[type="checkbox"]:checked + label:after,
input.checkboxres[type="checkbox"]:not(:checked) + label:after {
    content: '\2714'; 
    width: 19px;
    height: 19px;
    /* background: rgb(27, 153, 139); */
    /* border: 1px solid rgb(27, 153, 139); */
    position: absolute;
    top: -1px;
    left: 3px;
    color: #fff;
    /* border-radius: 50px; */
    /* left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); */
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
input.checkboxres[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
input.checkboxres[type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

input.likertscaleres[type="radio"]:checked,
input.likertscaleres[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input.dicotomyres[type="radio"]:checked,
input.dicotomyres[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input.dicotomyres[type="radio"]:checked + label,
input.dicotomyres[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
input.dicotomyres[type="radio"]:checked + label:before,
input.dicotomyres[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgb(52, 58, 64);;
    border-radius: 100%;
    background: #fff;
}
input.dicotomyres[type="radio"]:checked + label:after,
input.dicotomyres[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 20px;
    height: 20px;
    background: rgb(27, 153, 139);
    border: 1px solid rgb(27, 153, 139);
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: 50px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
input.dicotomyres[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
input.dicotomyres[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

input.thumbsupres[type="radio"]:checked,
input.thumbsupres[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input.ratingscale[type="radio"]:checked,
input.ratingscale[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input.ratingscale[type="radio"]:checked + label,
input.ratingscale[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-top: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
input.ratingscale[type="radio"]:checked + label:before,
input.ratingscale[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: -4px;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgb(52, 58, 64);;
    border-radius: 100%;
    background: #fff;
}
input.ratingscale[type="radio"]:checked + label:after,
input.ratingscale[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 20px;
    height: 20px;
    background: rgb(27, 153, 139);
    border: 1px solid rgb(27, 153, 139);
    position: absolute;
    top: -1px;
    left: -5px;
    border-radius: 50px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
input.ratingscale[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
input.ratingscale[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

input.starratingres[type="radio"]:checked,
input.starratingres[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input.starratingres[type=radio]:checked + label .svg-inline--fa {
    color: #eec231 !important;
}
input.thumbsuprating[type="radio"]:checked,
input.thumbsuprating[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input.thumbsuprating[type=radio]:checked + label .svg-inline--fa {
    color: #1b998a !important;
}

div input.thumbsuprating[type=radio]:checked ~ label .svg-inline--fa {
    color: #1b998a !important;
}
input.loverating[type="radio"]:checked,
input.loverating[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input.loverating[type=radio]:checked + label .svg-inline--fa {
    color: #da468b !important;
}
input.nps[type="radio"]:checked,
input.nps[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input.nps[type="radio"]:checked + label,
input.nps[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-top: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
input.nps[type="radio"]:checked + label:before,
input.nps[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: -4px;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgb(52, 58, 64);;
    border-radius: 100%;
    background: #fff;
}
input.nps[type="radio"]:checked + label:after,
input.nps[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 20px;
    height: 20px;
    background: rgb(27, 153, 139);
    border: 1px solid rgb(27, 153, 139);
    position: absolute;
    top: -1px;
    left: -5px;
    border-radius: 50px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
input.nps[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
input.nps[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

div.nps-label {
    font-size: 13px;
    font-weight: 500;
    color: #424242;
    padding:0;
    margin-top: 10px;
}

div.numeric-box-label {
    font-size: 13px;
    font-weight: 500;
    color: #424242;
    padding:0;
    margin-top: 10px;
}

.numeric-range, .numeric-range input {
    width: 100%;
}
div.numeric-box-label {
    font-size: 13px;
    font-weight: 500;
    color: #424242;
    padding:0;
    margin-top: 10px;
}

.numeric-range, .numeric-range input {
    width: 220px;
}
input.nps[type="radio"]:checked,
input.nps[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input.nps[type="radio"]:checked + label,
input.nps[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-top: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
input.nps[type="radio"]:checked + label:before,
input.nps[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: -4px;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgb(52, 58, 64);;
    border-radius: 100%;
    background: #fff;
}
input.nps[type="radio"]:checked + label:after,
input.nps[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 20px;
    height: 20px;
    background: rgb(27, 153, 139);
    border: 1px solid rgb(27, 153, 139);
    position: absolute;
    top: -1px;
    left: -5px;
    border-radius: 50px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
input.nps[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
input.nps[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

div.nps-label {
    font-size: 13px;
    font-weight: 500;
    color: #424242;
    padding:0;
    margin-top: 10px;
}

input.nps[type='radio']:checked,
input.nps[type='radio']:not(:checked) {
	position: absolute;
	left: -9999px;
}
input.nps[type='radio']:checked + label,
input.nps[type='radio']:not(:checked) + label {
	position: relative;
	padding-top: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: #666;
}
input.nps[type='radio']:checked + label:before,
input.nps[type='radio']:not(:checked) + label:before {
	content: '';
	position: absolute;
	left: -4px;
	top: 0;
	width: 18px;
	height: 18px;
	border: 1px solid rgb(52, 58, 64);
	border-radius: 100%;
	background: #fff;
}
input.nps[type='radio']:checked + label:after,
input.nps[type='radio']:not(:checked) + label:after {
	content: '';
	width: 20px;
	height: 20px;
	background: rgb(27, 153, 139);
	border: 1px solid rgb(27, 153, 139);
	position: absolute;
	top: -1px;
	left: -5px;
	border-radius: 50px;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
input.nps[type='radio']:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}
input.nps[type='radio']:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

div.nps-label {
	font-size: 13px;
	font-weight: 500;
	color: #424242;
	padding: 0;
	margin-top: 10px;
}

.react-datepicker__input-container input {
	padding: 5px;
}

/* * {
    box-sizing: border-box;
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    line-height: 1.5;
    color: #24292e;
    background-color: #fff;
  } */
  /* .contact-card {
    background: white;
    max-width: 800px;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #ddd;
} */
  .contact-card {
    position: relative;
    margin: 10px auto;
    width: 100%;
    background: white;
    max-width: 800px;
    padding: 15px;
    border-radius: 4px;
    /* height: 380px;
    overflow-y: auto; */
  }

  @media (min-width: 768px) and (max-width: 992px) {
    .contact-card {
      width: 80%;
    }
  }

  @media (max-width: 767px) {
    .contact-card {
      width: 90%;
    }
  }

  .contact-card div {
    margin-bottom: 10px;
  }
  
  .contact-card a {
    color: #08c;
  }
  
  .contact-card code {
    background: #eee;
    padding: .1rem;
    font-family: 'Menlo';
    font-size: 13px;
    color: #ff00aa;
  }
  
  .contact-card input {
    padding: .5rem;
    font-size: 16px;
    width: 100%;
    display: block;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .contact-card select {
    padding: .5rem;
    font-size: 16px;
    width: 100%;
    display: block;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: white;
  }
  
  .contact-card input:focus, .contact-card select:focus {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
  }
  
  /* input.error {
    border-color: red;
  } */
  
  .contact-card label {
    font-weight: bold;
    display: block;
    margin-bottom: .5rem;
  }
  
  /* .input-feedback {
    color: red;
    margin-top: .25rem;
  } */
  
  .contact-card button {
    padding: 2px 12px;
    border-style: none;
    border-radius: 2px;
    background-color: #1B998B;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
    
  }
  
  .contact-card button:disabled {
    opacity: .5;
    cursor: not-allowed !important;
  }
  
  .contact-card button + button {
    margin-left: .5rem;
  }
  
  .contact-card button.outline {
    background-color: #eee;
    border: 1px solid #aaa;
    color: #555;
  }
  
  
  
.spin {
    /* height: 70px;
    width: 70px;
    border-radius: 50%;
    border:dashed 5px white; */
    -webkit-animation-name: spin;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
  }
  
  @-webkit-keyframes spin {
    from   {  -webkit-transform: rotate(0deg); }
    to   {  -webkit-transform: rotate(360deg); }
  }
/* #clickable { cursor: pointer; }  */

.headerOfDistribution { background: rgb(239, 243, 244); } 

.headerOfDistribution > th { background: rgb(239, 243, 244); position: -webkit-sticky; position: sticky; top: -2px; font-size: 13px; } 

.rowHover tr:nth-child(even) { background-color: transparent; } 

.rowHover tr > td { font-size: 13px; }


.dist-list {
    width: 80%;
    margin: 0 auto;
}
.dist-list table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.dist-list td,.dist-list  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.dist-list tr:nth-child(even) {
    background-color: #dddddd;
}

.dist-list .dist-list > a {
    display: inline-block;
    outline: none;
    align-self: flex-start;
    border: 0 solid grey;
    background-color: purple;
    text-decoration: none;
    color: white;
    /* border-radius: 2px; */
    position: relative;
    margin: 10px 0;
    padding: 5px 10px;
    top: 0;
    left: 0;
    /* box-shadow: 2px 2px 10px 1px grey;  */
    z-index: 5;
}

.dist-list > a:hover {
    text-decoration: none;
    background-color: purple;
    color: white;
    box-shadow: 1px 1px 4px 1px grey; 
}

.dist-list > a:active { outline: none; }


.DashCard {
    background-color: rgba(27, 153, 139, .9) !important;
    color: #FFF;
    height: 100%;
    border: none !important;
    border-radius: 4px !important;
}

.DashCard .DashCardContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.DashCard .DashCardContent .flex-item-left {
    flex-basis: 70%;
}

.DashCard .DashCardContent .flex-item-right {
    flex-basis: 30%;
    text-align: right;
    font-size: 36px;
    font-weight: 700;
    color: #083836;
    border: none;
    border-radius: 3px;
}

.DashCard-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
    color: #083836;
}

.DashCard-description {
    font-size: 12px;
    line-height: 16px;
    color: #FFF;
    margin-top: 5px;
    font-weight: 300;
    margin-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .DashCard-title {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
        color: #083836;
    }

    .DashCard-description {
        font-size: 11px;
        line-height: 11px;
        color: #FFF;
        margin-top: 5px;
        font-weight: 300;
        margin-bottom: 0;
    }

    .DashCard .DashCardContent .flex-item-right {
        font-size: 18px;
        font-weight: 700;
    }
}

@media (min-width: 1200px) and (max-width: 1366px) {
    .DashCard .DashCardContent .flex-item-right {
        font-size: 30px;
        font-weight: 700;
    }
}
.RunningProject .running-project-title {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color:#083836;
}

.RunningProject .running-project-data {
    font-size: 11px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    color:#083836;
}

.RunningProject a {
    color: #FFF;
    border-radius: 2px;
    padding: 4px 3px;
    background-color: rgba(27, 153, 139, .6);
}

.RunningProject a:hover {
    color: #FFF;
    background-color: rgba(27, 153, 139, .9);
} 
.CardContainer {
    border: none !important;
    background-color: #EFF3F4 !important;
    border-top: 3px solid rgba(27, 153, 139, .9) !important;
    margin-bottom: 5px;
    border-radius: 3px 3px 4px 4px !important;
}
.ChannelPill {
    padding: 5px 5px;
    text-transform: uppercase;
    background-color: #FFF;
    border-radius: 3px;
    border: 1px solid rgba(27, 153, 139, 1);
    margin: 6px;
    min-width: 100px;
    text-align: center;
}

.ChannelPill .channel-pill-title {
    color: #083836;
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 12px;
}

.ChannelPill .channel-pill-info {
    color: #083836; 
    text-align: center; 
    font-size: 12px; 
    font-weight: 700; 
    margin-bottom: 0;
}
.SparkLine .SparkLine-tooltip {
    background-color: #FFF;
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 500;
    width: 90px !important;
    word-wrap: break-word;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color:#212121;
}

.SparkLine .SparkLine-tooltip > h6 {
    margin: 0;
    font-size: 10px;
    font-weight: 700;
    border-bottom: 1px solid #BDBDBD;
    color: #212121;
}
.CardSummary {
    box-shadow: 0px 1px 10px rgba(84, 110, 122, 0.16), 0 2px 3px rgba(84, 110, 122, 0.23);
    border: none;
    height: 100%;
}

.CardSummary .summary-title {
    font-size: 15px;
    margin-bottom: 5px;
    color: #071E3D;
    font-weight: 500;
    
}


.CardSummary .summary-value {
    font-weight: 300;
    font-size: 24px;
    color: #1B998B;
}
.C3-PieChart .c3-legend-item {
    font-size: 11px;
    fill: #424242;
    font-weight: 700;
}

.PieChartIcon {
    font-size: 13px;
    margin-top: -10px;
    color: #424242;
    font-weight: 500;
}
.CircularProgress h6.title {
    color:#424242;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
}

.flex-wrapper {
    display: flex;
    flex-flow: row nowrap;
}

.CircularProgress .single-chart {
    width: 33%;
    justify-content: space-around;
}

.CircularProgress .circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 250px;
}

.CircularProgress .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
}

.CircularProgress .circle {
    fill: none;
    stroke-width: 3.8;
    stroke-linecap: round;
    -webkit-animation: progress 1s ease-out forwards;
            animation: progress 1s ease-out forwards;
}

@-webkit-keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

.CircularProgress .circular-chart.high .circle {
    stroke: rgba(27, 232, 143, .7);
}

.CircularProgress .circular-chart.medium .circle {
    stroke: rgba(238, 203, 73, .8);
}

.CircularProgress .circular-chart.low .circle {
    stroke: rgba(255, 178, 56, .8);
}

.CircularProgress .circular-chart.very-low .circle {
    stroke: rgba(242, 73, 76, .8);
}

.CircularProgress .percentage {
    fill: #616161;
    font-family: sans-serif;
    font-size: 5px;
    font-weight: 700;
    text-anchor: middle;
}
.CardView {
  box-shadow: 0px 1px 10px rgba(84, 110, 122, 0.16),
    0 2px 3px rgba(84, 110, 122, 0.23);
  border: none;
}

.CardView .card-header {
  background-color: #ffffff;
  padding-bottom: 10px;
  padding-top: 8px;
}

.CardView .card-header .card-view-title {
  font-size: 15px;
  color: #071e3d;
  margin-top: 10px;
}

.CardView .card-header .response-counter {
  font-size: 10px;
  font-weight: 500;
  color: #071e3d;
}

.CardView .card-header .sparkline-legend {
  font-size: 10px;
  margin-bottom: 0;
  font-weight: 500;
}

.CardView .card-header .dropdown {
  top: 45%;
  left: 83%;
  width: 60px;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.14);
}
.CardView ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.CardView li {
  padding: 1px 12px;
  font-size: 13px;
}
.CardView li:hover {
  background-color: #1b998b;
  cursor: pointer;
}

.semicircle-gauge .value-text {
  display: none;
}

.Line .title {
    font-size: 14px;
    font-weight: 700;
    color: #424242;
}


.Line .Line-tooltip {
    background-color: #FFF;
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 700;
    width: 100px !important;
    word-wrap: break-word;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color:#424242;
}

.Line .Line-tooltip > h6 {
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    border-bottom: 1px solid #BDBDBD;
    color: #212121;
}
.HeaderInfoCard {
    color: #071E3D;
    font-size: 14px;
    font-weight: 700; 
}

.HeaderInfoCard .header-info > span {
    color: #1B998B; 
    font-size: 20px;
    font-weight: 300;
    margin-right: 15px;
}
.info-bar .info small {
    display: block;
    line-height:16px;
}

.info-bar .info .info-value {
    /* font-size: 12px; */
    font-weight: 700;
}

.info-bar .info .info-text {
    /* font-size: 10px; */
    font-weight: 700;
    text-transform: uppercase;
    color: #4b4848;
} 

.NPS {
    padding-top: 15px;
}

.NPS .progress-bar {
    border-right: 1px solid #FFFFFF;
    border-radius: 1px;
}

.NPS .progress-bar:last-child {
    border: none;
}

.NPS > .progress .bgDanger {
    background-color: #F27D7D;
    opacity: .9;
}

.NPS > .progress .bgWarning {
    background-color: #FFBF69;
    opacity: .9;
}

.NPS > .progress .bgSuccess {
    background-color: #1B998B;
    opacity: .9;
}

.NPS > .nps-value {
    font-size: 14px;
    font-weight: 700;
    color: #212121;
}

.NPS > .nps-value > .nps-text {
    font-weight: 400;
    padding: 0 5px;
    color: #424242;
}
.NpsDescriptor {
    font-size: 13px;
    font-weight: 700;
    color:#424242;
}

.NpsDescriptor span {
    padding:0 30px 0 0;
}

.NpsDescriptor span:last-child {
    padding:0;
}
.CoreAnalytics .core-links {
	font-size: 15px;
	font-weight: 700;
	color: #1b998b;
	cursor: pointer;
}

.react-datepicker__input-container input {
	padding: 5px;
	width: 100px;
}

.Advance-analytics {
    text-align: center;
}

.Advance-analytics .card {
    box-shadow: 0px 1px 10px rgba(84, 110, 122, 0.16), 0 2px 3px rgba(84, 110, 122, 0.23);
    border: none;
}
#feature {
    background: #FFFFFF;
    padding: 8px 0;
}

#high-level-object {
    background: #FCFCFC;
    border-top: 1px solid #EEEEEE;
    padding: 8px 0;
}

#low-level-object {
    background: #FCFCFC;
    border-bottom: 1px solid #EEEEEE;
    border-top: 1px solid #EEEEEE;
    padding: 8px 0;
}

#feature>div,
#high-level-object>div,
#low-level-object>div {
    font-weight: 500;
    font-size: 14px;
    color: #616161;
    cursor: pointer;
    margin-right: 10px;
    line-height: 22px;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

#low-level-object>div {
    color: #424242;
    font-size: 13px;
    font-weight: 500;
}

#feature>div {
    font-size: 16px;
    font-weight: 700;
    position: relative;
}


#feature .active {
    color: #1B998B;
    border-bottom: 2px solid #1B998B;
}


#high-level-object .active,
#low-level-object .active {
    color: #1B998B;
    border-bottom: 1px solid #1B998B;
}

@media (max-width: 991.98px) {

    #feature,
    #high-level-object,
    #low-level-object {
        padding: 6px 0;
    }

    #feature>div {
        font-size: 14px;
    }

    #high-level-object>div {
        font-size: 12px;
    }

    #low-level-object>div {
        font-size: 12px;
    }
}


@media (max-width: 767.98px) {

    #feature,
    #high-level-object,
    #low-level-object {
        padding: 6px 0;
    }

    #feature>div {
        font-size: 13px;
        line-height: 20px;
    }

    #high-level-object>div {
        font-size: 12px;
        line-height: 20px;
    }

    #low-level-object>div {
        font-size: 11px;
        line-height: 18px;
    }
}
.Gauge {
    position: relative;
    padding: 0px 5px;
}

.gauge-title {
    color:#424242;
    font-size: 14px;
    font-weight: 700;
    height:20px;
}

.Gauge .value {
    position: absolute;
    bottom: 22%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.Gauge .value h6, .Gauge .value p{
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: #424242;
    line-height: 1rem;
}

.Gauge .value h6 {
    font-size: 14px;
    color: #212121;
}

.Gauge .value .legened {
    font-size: 10px;
    line-height: 8px;
    color: #616161;
}
.ResponeCategory {
    color:#424242;
    font-size: 13px;
    padding: 15px 15px;
}

.ResponeCategory ul {
    list-style: none;
}

.ResponeCategory ul > li {
    border:none;
    padding:0;
}

.ResponeCategory ul > li:first-child {
    margin-bottom: 5px;
}

.ResponeCategory ul > li > span {
    font-weight: 700;
    color: #212121;
}
.MediaObject {
    list-style: none;
    margin:0;
    padding:0;
    text-align: left;
    font-size: 13px;
    color: #212121;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
}

.MediaObject li {
    padding: 5px 2px;
    line-height: 18px;
}

.MediaObject li > span.indicator {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 4px;
    margin-bottom: -2px;
    border-radius: 2px;
    border: 2px solid #2b2828;
}

.dangerBlock {
    background-color: #F27D7D;
}

.successBlock {
    background-color: #1B998B;
}
@media (max-width: 991px) { 
    .VerticalBar {
        margin-bottom: 60px;
    }
}

.VerticalBar {
    margin-bottom: 0px;
}
.border-right-cl-3,
.border-right-cl-2 {
    border-right: 1px solid #dee2e6;
}

@media (max-width: 575.98px) {
    .border-right-cl-3,
    .border-right-cl-2 {
        border-right: none;
    }
}

@media (min-width:576px) and (max-width: 767.98px) {
    .border-right-cl-3:nth-child(2n),
    .border-right-cl-2:nth-child(2n) {
        border-right: none;
    }
}

@media (min-width:768px) and (max-width: 991.98px) {
    .border-right-cl-3:nth-child(2n),
    .border-right-cl-2:nth-child(2n) {
        border-right: none;
    }
}

@media (min-width:992px) and (max-width: 1199.98px) {
    .border-right-cl-3:nth-child(3n) {
        border-right: none;
    }

    .border-right-cl-2:nth-child(4n) {
        border-right: none;
    }
}

@media (min-width: 1200px) {
    .border-right-cl-3:nth-child(4n) {
        border-right: none;
    }

    .border-right-cl-2:nth-child(6n) {
        border-right: none;
    }
}

.light {
    height: 35px;
    width: 35px;
    display: inline-block;
    border-radius: 
    50%;
    margin: 0 4px;
}

.light-green {
    background-color: rgba(0, 230, 118, .8);
    /* background-color: rgba(42, 185, 123, 0.5); */
    /* -webkit-box-shadow: 3px 2px 32px 7px rgba(0, 230, 118, 1);
    -moz-box-shadow: 3px 2px 32px 7px rgba(0, 230, 118, 1);
    box-shadow: 3px 2px 32px 7px rgba(0, 230, 118, 1); */
}

.light-yellow {
    background-color: rgba(255, 235, 59, .8);
    /* -webkit-box-shadow: 3px 2px 32px 7px rgba(255, 235, 59, 1);
    -moz-box-shadow: 3px 2px 32px 7px rgba(255, 235, 59, 1);
    box-shadow: 3px 2px 32px 7px rgba(255, 235, 59, 1); */
}

.light-red {
    background-color: rgba(255, 23, 68, .8);
    /* background-color: rgba(165, 1, 34, 0.8); */
    /* -webkit-box-shadow: 3px 2px 32px 7px rgba(255, 23, 68, 1);
    -moz-box-shadow: 3px 2px 32px 7px rgba(255, 23, 68, 1);
    box-shadow: 3px 2px 32px 7px rgba(255, 23, 68, 1); */
}

.green-active {
    background-color: rgba(0, 230, 118, 1);
    box-shadow: 0px 0px 11px 6px rgba(0, 230, 118, 1);
}

.yellow-active {
    background-color: rgba(255, 235, 59, 1);
    box-shadow: 0px 0px 11px 6px rgba(255, 235, 59, 1);
}

.red-active {
    background-color: rgba(255, 23, 68, 1);
    box-shadow: 0px 0px 11px 6px rgba(255, 23, 68, 1);
}




.C3-Donut .c3-chart-arcs-title {
    font-size: 11px;
    fill: #212121;
    font-weight: 700;
    dominant-baseline:middle;
}

.C3-Donut .c3-tooltip-container {
    background-color: #FFF;
    color: #212121;
    font-size: 13px;
    font-weight: 700;
    z-index: 1;
}

.C3-Donut .c3-legend-item {
    font-size: 11px;
    fill: #424242;
    font-weight: 700;
}
.ReactDatePicker .react-datepicker {
    font-size: .8rem;
    border: 1px solid #E0E0E0;
}

.ReactDatePicker .react-datepicker__header {
    border-bottom: none;
    background-color: #F5F5F5;
}

.ReactDatePicker .react-datepicker__input-container input {
    padding: 6px 4px;
    border-radius: 2px;
    border: 1px solid #BDBDBD;
    font-size: 11px;
    width: 68px;
}

.ReactDatePicker .react-datepicker__day {
    background-color: #EEE;
    border-radius: 2px;
}

.ReactDatePicker .react-datepicker__day--selected {
    background-color: rgb(15, 209, 144); 
}

.ReactDatePicker .react-datepicker__week {
    border-bottom: 1px solid #E0E0E0;
}

.ReactDatePicker .react-datepicker__week:last-child {
    border: none;
}

.ReactDatePicker .react-datepicker__year-select,
.ReactDatePicker .react-datepicker__month-select {
    border:none;
    padding:2px;
    border-radius: 2px;
    color: #424242;
    font-weight: 700;
}
.ReactMonthPicker .react-datepicker {
    font-size: .8rem;
    border: 1px solid #E0E0E0;
}

.ReactMonthPicker .react-datepicker__header {
    border-bottom: none;
    background-color: #F5F5F5;
}

.ReactMonthPicker .react-datepicker__input-container input {
    padding: 6px 4px;
    border-radius: 2px;
    border: 1px solid #BDBDBD;
    font-size: 11px;
    width: 68px;
}

.ReactMonthPicker .react-datepicker__month-wrapper {
    color:#212121;
    border-bottom: 1px solid #EEE;
    padding: 2px 0px;
}

.ReactMonthPicker .react-datepicker__month-wrapper:last-child {
    border: none;
}

.ReactMonthPicker .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    /* margin: 2px; */
    border-radius: 4px;
    padding: 2px 0;
}    
.DateRange h6 {
    font-size: 14px;
    font-weight: 700;
    color: #424242;
}


.DateRange .react-datepicker {
    font-size: .8rem;
    border: 1px solid #E0E0E0;
}

.DateRange .react-datepicker__header {
    border-bottom: none;
    background-color: #F5F5F5;
}

.DateRange .start-date .react-datepicker__input-container input {
    padding: 6px 6px 6px 3px;
    border-radius: 2px;
    border: 1px solid #BDBDBD;
    font-size: 11px;
    width: 80px;
    max-width: 80px;
}

.DateRange .end-date .react-datepicker__input-container input {
    padding: 6px 5px;
    border-radius: 2px;
    border: 1px solid #BDBDBD;
    font-size: 11px;
    width: 70px;
    max-width: 70px;
}

.DateRange .react-datepicker__close-icon {
    padding: 0px 2px 0px 0px;
}

.DateRange .react-datepicker__day {
    background-color: #EEE;
    border-radius: 2px;
}

.DateRange .react-datepicker__day--today {
    background-color: rgb(15, 209, 144); 
}

.DateRange .react-datepicker__day--selected {
    background-color: rgb(15, 209, 144); 
}

.DateRange .react-datepicker__year-select,
.DateRange .react-datepicker__month-select {
    border:none;
    padding:2px;
    border-radius: 2px;
    color: #424242;
    font-weight: 700;
}

.DateRange .react-datepicker__day--selected,  
.DateRange .react-datepicker__day--in-selecting-range,
.DateRange .react-datepicker__day--in-range,
.DateRange .react-datepicker__month-text--selected,
.DateRange .react-datepicker__month-text--in-selecting-range,
.DateRange .react-datepicker__month-text--in-range,
.DateRange .react-datepicker__quarter-text--selected,
.DateRange .react-datepicker__quarter-text--in-selecting-range,
.DateRange .react-datepicker__quarter-text--in-range {
    background-color: rgb(15, 209, 144);    
}
.Filter .title {
    color: #424242;
    font-weight: 700;
    font-size: 14px;
    text-align: left;
}

.Filter .period {
    color: #616161;
    font-weight: 700;
    font-size: 12px;
    text-align: left;
}

.Filter .btnFilter {
    font-size: 10px;
    font-weight: 700;
    background-color: #0EAD69;
    border-radius: 2px;

}

.Filter select.form-control {
    color: #424242;
    font-size: 11px;
    font-weight: 700;
    width:75px;
    padding: 0 1px;
}

.Filter select.form-control .filterTypeOption {
    font-size: 12px;
}

@media (max-width: 576px) {
    .Filter .card-body {
        padding : 10px 8px;
    }
}


.srv-validation-message {
    color: red
}

#clickable { cursor: pointer; } 

#header { background: rgb(239, 243, 244); } 

#header > th { background: rgb(239, 243, 244); position: -webkit-sticky; position: sticky; top: -2px; font-size: 13px; } 

.rowHover tr:nth-child(even) { background-color: transparent; } 

.rowHover tr > td { font-size: 13px; } 

#clickable { cursor: pointer; } 

#header { background: rgb(239, 243, 244); } 

#header > th { background: rgb(239, 243, 244); position: -webkit-sticky; position: sticky; top: 0px; font-size: 13px;  } 

.rowHover tr:nth-child(even) { background-color: transparent; } 

.rowHover tr > td { font-size: 13px; } 
.btnHover:hover { 
    background: #0B6157 !important; 
    border-color: #0B6157 !important;
} 



#clickable { cursor: pointer; } 

#header { background: rgb(239, 243, 244); } 

#header > th { background: rgb(239, 243, 244); position: -webkit-sticky; position: sticky; top: 0px; font-size: 13px;  } 

.rowHover tr:nth-child(even) { background-color: transparent; } 

.rowHover tr > td { font-size: 13px; } 
.btnHover:hover { 
    background: #0B6157 !important; 
    border-color: #0B6157 !important;
} 



#clickable { cursor: pointer; } 

#header { background: rgb(239, 243, 244); } 

#header > th { background: rgb(239, 243, 244); position: -webkit-sticky; position: sticky; top: -2px; font-size: 13px; } 

.rowHover tr:nth-child(even) { background-color: transparent; } 

.rowHover tr > td { font-size: 13px; } 
.btnHover:hover { 
    background: #0B6157 !important; 
    border-color: #0B6157 !important;
} 



#clickable { cursor: pointer; } 

#header { background: rgb(239, 243, 244); } 

#header > th { background: rgb(239, 243, 244); position: -webkit-sticky; position: sticky; top: -2px; font-size: 13px; } 

.rowHover tr:nth-child(even) { background-color: transparent; } 

.rowHover tr > td { font-size: 13px; } 
.btnHover:hover { 
    background: #0B6157 !important; 
    border-color: #0B6157 !important;
} 


.css-yk16xz-control {
  border-radius: 4px 0 0 4px !important;
}
.ck.ck-editor__main>.ck-editor__editable {
  height: 300px;
} 
.form-control-file { 
  width: auto !important;
  display: inline-block !important;
} 

#clickable { cursor: pointer; } 

#header { background: rgb(239, 243, 244); } 

#header > th { background: rgb(239, 243, 244); position: -webkit-sticky; position: sticky; top: -2px; font-size: 13px; } 

.rowHover tr:nth-child(even) { background-color: transparent; } 

.rowHover tr > td { font-size: 13px; } 
.btnHover:hover { 
    background: #0B6157 !important; 
    border-color: #0B6157 !important;
} 


.btnHover:hover { 
    background: #0B6157 !important; 
    border-color: #0B6157 !important;
} 



#clickable { cursor: pointer; } 

#header { background: rgb(239, 243, 244); } 

#header > th { background: rgb(239, 243, 244); position: -webkit-sticky; position: sticky; top: -2px; font-size: 13px; } 

.rowHover tr:nth-child(even) { background-color: transparent; } 

.rowHover tr > td { font-size: 13px; } 
.dislabel {
    width: 100%;
}
  
.inputname {
    border-radius: 0;
    border: 1px solid hsl(0,0%,80%);
    box-shadow: 1px 1px 5px 1px #D9D9D9;
    background: white;
    width: 100%;
    margin-bottom: 10px;
    padding: 2px 5px;
}

.dislabel {
    display: block;
}
input[type="checkbox"], .selectstatus {
    margin-left: 5px;
}
.dislabel {
    width: 100%;
}
  
.inputname {
    border-radius: 0;
    border: 1px solid hsl(0,0%,80%);
    box-shadow: 1px 1px 5px 1px #D9D9D9;
    background: white;
    width: 100%;
    margin-bottom: 10px;
    padding: 2px 5px;
}

.dislabel {
    display: block;
}
input[type="checkbox"], .selectstatus {
    margin-left: 5px;
}
.dislabel {
    width: 100%;
}
  
.inputname {
    border-radius: 0;
    border: 1px solid hsl(0,0%,80%);
    box-shadow: 1px 1px 5px 1px #D9D9D9;
    background: white;
    width: 100%;
    margin-bottom: 10px;
    padding: 2px 5px;
}

.dislabel {
    display: block;
}
input[type="checkbox"], .selectstatus {
    margin-left: 5px;
}
.dislabel {
    width: 100%;
}
  
.inputname {
    border-radius: 0;
    border: 1px solid hsl(0,0%,80%);
    box-shadow: 1px 1px 5px 1px #D9D9D9;
    background: white;
    width: 100%;
    margin-bottom: 10px;
    padding: 2px 5px;
}

.dislabel {
    display: block;
}
input[type="checkbox"], .selectstatus {
    margin-left: 5px;
}
@media only screen and (max-width: 425px){
  .react-select__control{
    font-size: 10px;
  }
  /* .react-select-container{
    width: 50% !important;
  } */
}
.step .step-text {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: #757575;
}

.step #test {
    position: relative;
}

.step .line {
    position: absolute;
    top:-8px;
    right:-48%;
    width: 95%;
    display: inline-block;
    height: 2px;
    background-color: #BDBDBD;
    border: none;
    z-index: 0!important;
}

@media (max-width: 581.92px) {
    .step .line {
        right:-54%;
        width: 97%;
    }
}
.channel-wrapper {
  padding: 10px;
  margin: 10px;
  background: white;
  margin-bottom: 25px;
}
.channel-wrapper > div {
  width: 25%;
  float: left;
  box-shadow: 1px 1px 8px 1px grey;
  margin: 0;
  padding: 0;
  min-height: 100px;
  margin-right: 10px;
}
.channel-wrapper h4 {
  margin: 0;
  padding: 10px;
  display: block !important;
  text-transform: capitalize;
  background-color: #310d31;
  color: #fff;
  cursor: pointer;
}
.dislabel {
  display: block;
}
.generate {
  display: block;
  outline: none;
  /* align-self: flex-start; */
  border: 0 solid grey;
  background-color: purple;
  text-decoration: none;
  color: white;
  position: relative;
  margin: 10px;
  padding: 5px 10px;
  top: 0;
  left: 0;
  z-index: 5;
}
.generate:hover {
  text-decoration: none;
  background-color: purple;
  color: white;
  box-shadow: 1px 1px 4px 1px grey;
}

.generate:active {
  outline: none;
}

.container-inner {
  padding: 0px !important;
  margin-left: 55px !important;
}

.progressbar-redirecturl {
  /* position: absolute;
    bottom: 0;
    right: 0;
    width: 380px;
    height: 100%;
    overflow-y: auto;
    background: #eff3f4;
    padding: 0px !important;
    border-radius: 4px;
    margin-bottom: 0 !important; */
  height: 100%;
  border-radius: 4px 0px 0px 0px;
  background-color: #eff3f4;
}

.settings-row {
  margin: 0 !important;
  padding: 0px 21px 21px 21px;
}

@media (max-width: 991.98px) {
  .container-inner {
    max-width: 100vw;
    margin-left: 0 !important;
  }

  .progressbar-redirecturl {
    position: static;
    width: 100%;
    margin: 0px auto !important;
    margin-bottom: 10px !important;
  }
}

.progressbar-redirecturl label {
  background: white;
  min-height: 80px;
  cursor: pointer;
  display: block;
  padding: 10px;
  border-radius: 4px;
}

.progressbar-redirecturl input[type='text'] {
  max-width: 250px;
}

p {
    text-align: left;
    text-align: initial;
}
#SurveyDesignEditor .ck.ck-editor__main>.ck-editor__editable {
    height: 125px;
}
.channel-wrapper {
  padding: 10px;
  margin: 10px;
  background: white;
  margin-bottom: 25px;
}
.channel-wrapper > div {
  width: 25%;
  float: left;
  box-shadow: 1px 1px 8px 1px grey;
  margin: 0;
  padding: 0;
  min-height: 100px;
  margin-right: 10px;
}
.channel-wrapper h4 {
  margin: 0;
  padding: 10px;
  display: block !important;
  text-transform: capitalize;
  background-color: #310d31;
  color: #fff;
  cursor: pointer;
}
.dislabel {
  display: block;
}
.generate {
  display: block;
  outline: none;
  /* align-self: flex-start; */
  border: 0 solid grey;
  background-color: purple;
  text-decoration: none;
  color: white;
  position: relative;
  margin: 10px;
  padding: 5px 10px;
  top: 0;
  left: 0;
  z-index: 5;
}
.generate:hover {
  text-decoration: none;
  background-color: purple;
  color: white;
  box-shadow: 1px 1px 4px 1px grey;
}

.generate:active {
  outline: none;
}

.container-inner {
  padding: 0px !important;
  margin-left: 55px !important;
}

.progressbar-redirecturl {
  /* position: absolute;
  bottom: 0;
  right: 0;
  width: 380px;
  height: 100%;
  overflow-y: auto;
  background: #eff3f4;
  padding: 0px !important;
  border-radius: 4px;
  margin-bottom: 0 !important; */
  height: 100%;
  border-radius: 4px 0px 0px 0px;
  background-color: #eff3f4;
}

.settings-row {
  margin: 0 !important;
  padding: 0px 21px 21px 21px;
}

@media (max-width: 991.98px) {
  .container-inner {
    max-width: 100vw;
    margin-left: 0 !important;
  }

  .progressbar-redirecturl {
    position: static;
    width: 100%;
    margin: 0px auto !important;
    margin-bottom: 10px !important;
  }
}

.progressbar-redirecturl label {
  background: white;
  min-height: 80px;
  cursor: pointer;
  display: block;
  padding: 10px;
  border-radius: 4px;
}

.progressbar-redirecturl input[type='text'] {
  max-width: 250px;
}



@media only screen and (max-width: 425px){
  .react-select__control{
    font-size: 10px;
  }
  /* .react-select-container{
    width: 50% !important;
  } */
}
@media only screen and (max-width: 425px){
  .react-select__control{
    font-size: 10px;
  }
  /* .react-select-container{
    width: 50% !important;
  } */
}


@media only screen and (max-width: 425px){
  .react-select__control{
    font-size: 10px;
  }
  /* .react-select-container{
    width: 50% !important;
  } */
}
@media only screen and (max-width: 425px) {
    .react-select__control {
        font-size: 10px;
    }

    /* .react-select-container {
        width: 50% !important;
    } */
}
/* .Select.is-open { position: relative; z-index: 1000; } */
.react-select-container .react-select__value-container {
  padding: 2px 5px;
}
.react-select-container .react-select__control {
  border-radius: 3px;
  border: 1px solid #BDBDBD;
  font-size: 12px;
  font-weight: 500;
  color: #212121;
  text-transform: uppercase;
  -webkit-transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.react-select-container .react-select__menu {
  border-radius: 4px;
}

.react-select-container .react-select__menu .react-select__option {
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  color: #424242;
  text-transform: uppercase;
} 

.react-select-container .react-select__menu .react-select__option:hover {
  background-color: rgb(135, 197, 190);
}

.react-select-container .react-select__menu .react-select__option--is-selected {
  background-color: #1B998B;
  color: #FFF;
}

.react-select-container .react-select__control.react-select__control--is-focused {
  border-color: #1B998B !important;
  box-shadow: none;
}

.react-select-container .react-select__control.react-select__control--menu-is-open {
    /* border-color: red !important; */
    box-shadow: none;
}

@media only screen and (max-width: 425px) {
  .react-select__control {
    font-size: 10px;
  }

  /* .react-select-container {
    width: 50% !important;
  } */
}


@media only screen and (max-width: 425px){
  .react-select__control{
    font-size: 10px;
  }
  /* .react-select-container{
    width: 50% !important;
  } */
}
.p-splitbutton .p-button {
  background-color: #1b998b !important;
  border-color: #1b998b !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.p-menu .p-menuitem-link {
  font-size: 12px;
}

.p-splitbutton .p-button:hover {
  background-color: #0b6157 !important;
  border-color: #0b6157 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-menu {
  max-height: 220px;
  overflow-y: auto;
}

#surveySectionProjectForm .col-form-label {
    font-size: 14px;
}

#surveySectionProjectForm .form-control {
    font-size: 13px;
    padding: 2px 10px;
    height: 30px;
}

#surveySectionProjectForm .col-form-label.checkbox {
    margin-left: 24px;
    line-height: 9px;
    font-weight: 500;
    color: #424242;
}

@media (max-width:767.92px) {
    #surveySectionProjectForm .col-form-label {
        font-size: 12px;
        line-height: 10px;
    }

    #surveySectionProjectForm .col-form-label.checkbox {
        margin-left: 24px;
    }

    #surveySectionProjectForm .form-control {
        font-size: 12px;
        padding: 2px 10px;
        height: 26px;
    }

}
.accordion__button {
    background: #fff;
    padding: 10px;
}
.survey-info-container {
  box-sizing: border-box;
  width: 100%;
  /* max-width: 1500px; */
  padding: 10px 25px 5px 25px;
  border-radius: 4px;
  background-color: #eff3f4;
  margin-bottom: 10px;
  /* margin-left: 55px !important; */
  /* margin-right: auto; */
}

@media (max-width: 1024px) {
  .survey-info-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.question-bank-container {
  /* position: absolute;
  bottom: 0;
  right: 0;
  width: 380px;
  overflow-y: auto; */
  height: 100%;
  border-radius: 4px 0px 0px 0px;
  background-color: #eff3f4;
}

.survey-questionbank-container {
  width: 100%;
  padding: 15px;
  border-radius: 4px;
  /* background-color: #eff3f4; */
  /* position: absolute;
  bottom: 0;
  right: 0; */
}

.survey-questionbank-container .survey-question-bank {
  background-color: #1b998b;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.survey-question-text {
  margin-left: 55px;
  font-size: 14px;
  font-weight: 700;
  color: #424242;
}

@media (max-width: 991.98px) {
  .survey-question-text {
    margin-left: 0;
  }

  .question-bank-container {
    /* make it static */
    position: static;
    width: 100%;
    height: auto;
    margin-top: 10px;
    /* display: none; */
  }

  .survey-info-container {
    margin-left: 0 !important;
  }
}

.btnDeleteHover:hover {
  background-color: #f2494c !important;
}

.channel-wrapper {
  padding: 10px;
  margin: 10px;
  background: white;
  margin-bottom: 25px;
}
.channel-wrapper > div {
  width: 25%;
  float: left;
  box-shadow: 1px 1px 8px 1px grey;
  margin: 0;
  padding: 0;
  min-height: 100px;
  margin-right: 10px;
}
.channel-wrapper h4 {
  margin: 0;
  padding: 10px;
  display: block !important;
  text-transform: capitalize;
  background-color: #310d31;
  color: #fff;
  cursor: pointer;
}
.dislabel {
  display: block;
}
.generate {
  display: block;
  outline: none;
  /* align-self: flex-start; */
  border: 0 solid grey;
  background-color: purple;
  text-decoration: none;
  color: white;
  position: relative;
  margin: 10px;
  padding: 5px 10px;
  top: 0;
  left: 0;
  z-index: 5;
}
.generate:hover {
  text-decoration: none;
  background-color: purple;
  color: white;
  box-shadow: 1px 1px 4px 1px grey;
}

.generate:active {
  outline: none;
}

.container-inner {
  padding: 0px !important;
  margin-left: 55px !important;
}

.progressbar-redirecturl {
  /* position: absolute;
  bottom: 0;
  right: 0;
  width: 380px;
  height: 100%;
  overflow-y: auto;
  background: #eff3f4;
  padding: 0px !important;
  border-radius: 4px;
  margin-bottom: 0 !important; */
  height: 100%;
  border-radius: 4px 0px 0px 0px;
  background-color: #eff3f4;
}

.settings-row {
  margin: 0 !important;
  padding: 0px 21px 21px 21px;
}

@media (max-width: 991.98px) {
  .container-inner {
    max-width: 100vw;
    margin-left: 0 !important;
  }

  .progressbar-redirecturl {
    position: static;
    width: 100%;
    margin: 0px auto !important;
    margin-bottom: 10px !important;
  }
}

.progressbar-redirecturl label {
  background: white;
  min-height: 80px;
  cursor: pointer;
  display: block;
  padding: 10px;
  border-radius: 4px;
}

.progressbar-redirecturl input[type='text'] {
  max-width: 250px;
}

.channel-wrapper {
  padding: 10px;
  margin: 10px;
  background: white;
  margin-bottom: 25px;
}
.channel-wrapper > div {
  width: 25%;
  float: left;
  box-shadow: 1px 1px 8px 1px grey;
  margin: 0;
  padding: 0;
  min-height: 100px;
  margin-right: 10px;
}
.channel-wrapper h4 {
  margin: 0;
  padding: 10px;
  display: block !important;
  text-transform: capitalize;
  background-color: #310d31;
  color: #fff;
  cursor: pointer;
}
.dislabel {
  display: block;
}
.generate {
  display: block;
  outline: none;
  /* align-self: flex-start; */
  border: 0 solid grey;
  background-color: purple;
  text-decoration: none;
  color: white;
  position: relative;
  margin: 10px;
  padding: 5px 10px;
  top: 0;
  left: 0;
  z-index: 5;
}
.generate:hover {
  text-decoration: none;
  background-color: purple;
  color: white;
  box-shadow: 1px 1px 4px 1px grey;
}

.generate:active {
  outline: none;
}

.container-inner {
  padding: 0px !important;
  margin-left: 55px !important;
}

.progressbar-redirecturl {
  /* position: absolute;
  bottom: 0;
  right: 0;
  width: 380px;
  height: 100%;
  overflow-y: auto;
  background: #eff3f4;
  padding: 0px !important;
  border-radius: 4px;
  margin-bottom: 0 !important; */
  height: 100%;
  border-radius: 4px 0px 0px 0px;
  background-color: #eff3f4;
}

.settings-row {
  margin: 0 !important;
  padding: 0px 21px 21px 21px;
}

@media (max-width: 991.98px) {
  .container-inner {
    max-width: 100vw;
    margin-left: 0 !important;
  }

  .progressbar-redirecturl {
    position: static;
    width: 100%;
    margin: 0px auto !important;
    margin-bottom: 10px !important;
  }
}

.progressbar-redirecturl label {
  background: white;
  min-height: 80px;
  cursor: pointer;
  display: block;
  padding: 10px;
  border-radius: 4px;
}

.progressbar-redirecturl input[type='text'] {
  max-width: 250px;
}

.channel-wrapper {
    padding: 10px; 
    margin: 10px;
    background: white;
    margin-bottom: 25px;
}
.channel-wrapper > div {
    width: 25%;
    float: left;
    box-shadow: 1px 1px 8px 1px grey;
    margin: 0;
    padding: 0;
    min-height: 100px;
    margin-right: 10px; 
}
.channel-wrapper h4 {
    margin: 0;
    padding: 10px;
    display: block !important;
    text-transform: capitalize;
    background-color: #310D31;
    color: #fff;
    cursor: pointer;
}
.dislabel {
    display: block;
}
.generate {
    display: block;
    outline: none;
    /* align-self: flex-start; */
    border: 0 solid grey;
    background-color: #1b998a;
    text-decoration: none;
    color: white;
    position: relative;
    margin: 10px;
    padding: 5px 10px;
    top: 0;
    left: 0;
    z-index: 5;
}
.generate:hover {
    text-decoration: none;
    background-color: #1b998a;
    color: white;
    box-shadow: 1px 1px 4px 1px grey; 
}

.generate:active { outline: none; }
.rwt__tablist h4 {
    font-size: 16px;
    margin: 0;
}

.rwt__tablist button {
    padding: 10px 15px;
}
.rwt__tablist[aria-orientation="vertical"] .rwt__tab {
    background: #e0e0e0;
    border-bottom: 1px solid #ccc;
}
.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"] {
    background: #1b998a;
    color: #ffffff;
}
.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
    border-right: 3px solid #1b998a !important;
}
.ck.ck-editor__main > .ck-editor__editable {
  height: 300px;
}

.channel-wrapper {
  padding: 10px;
  margin: 10px;
  background: white;
  margin-bottom: 25px;
}
.channel-wrapper > div {
  width: 25%;
  float: left;
  box-shadow: 1px 1px 8px 1px grey;
  margin: 0;
  padding: 0;
  min-height: 100px;
  margin-right: 10px;
}
.channel-wrapper h4 {
  margin: 0;
  padding: 10px;
  display: block !important;
  text-transform: capitalize;
  background-color: #310d31;
  color: #fff;
  cursor: pointer;
}
.dislabel {
  display: block;
}
.generate {
  display: block;
  outline: none;
  /* align-self: flex-start; */
  border: 0 solid grey;
  background-color: #1b998a;
  text-decoration: none;
  color: white;
  position: relative;
  margin: 10px;
  padding: 5px 10px;
  top: 0;
  left: 0;
  z-index: 5;
}
.generate:hover {
  text-decoration: none;
  background-color: #1b998a;
  color: white;
  box-shadow: 1px 1px 4px 1px grey;
}

.generate:active {
  outline: none;
}

.rwt__tablist[aria-orientation='vertical'] .rwt__tab[aria-selected='true'] {
  background: #1b998a;
  color: #ffffff;
}
.rwt__tablist[aria-orientation='vertical']
  .rwt__tab[aria-selected='true']:after {
  border-right: 3px solid #1b998a !important;
}

.distribution_tabs .options {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.distribution_tabs .options button {
  background: transparent;
  border: none;
  outline: none;
}

#clickable {
	cursor: pointer;
}

#header {
	background: rgb(235, 235, 235);
}

#header > th {
	background: rgb(235, 235, 235);
	position: -webkit-sticky;
	position: sticky;
	top: -2px;
}

.rowHover tr:nth-child(even) {
	background-color: transparent;
}

tbody .p-checkbox .p-checkbox-box.p-highlight {
	position: relative;
	background-color: transparent;
	border-color: #1b998b;
	color: #1b998b;
	z-index: -1;
}

tbody .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
	position: relative;
	background-color: transparent;
	border-color: #1b998b;
	color: #1b998b;
	z-index: -1;
}

.survey-table table td:nth-child(1),
.survey-table table th:nth-child(1),
.survey-table table td:nth-child(2),
.survey-table table th:nth-child(2),
.survey-table table td:nth-child(4),
.survey-table table th:nth-child(4),
.survey-table table td:nth-child(6),
.survey-table table th:nth-child(6),
.survey-table table td:nth-child(7),
.survey-table table th:nth-child(7) {
	text-align: left !important;
}

.dist-list {
    width: 80%;
    margin: 0 auto;
}
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #dddddd;
}

.dist-list > a {
    display: inline-block;
    outline: none;
    align-self: flex-start;
    border: 0 solid grey;
    background-color: purple;
    text-decoration: none;
    color: white;
    /* border-radius: 2px; */
    position: relative;
    margin: 10px 0;
    padding: 5px 10px;
    top: 0;
    left: 0;
    /* box-shadow: 2px 2px 10px 1px grey;  */
    z-index: 5;
}

.dist-list > a:hover {
    text-decoration: none;
    background-color: purple;
    color: white;
    box-shadow: 1px 1px 4px 1px grey; 
}

.dist-list > a:active { outline: none; }

a:hover {
    text-decoration: none !important;
}

.change-password {
    position: relative;
    margin: 10px auto;
    width: 100%;
    background: white;
    max-width: 600px;
    padding: 15px;
    border-radius: 4px;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    .change-password {
      width: 80%;
    }
  }

  @media (max-width: 767px) {
    .change-password {
      width: 90%;
    }
  }

  .change-password div {
    margin-bottom: 10px;
  }
  
  .change-password input {
    padding: .5rem;
    font-size: 16px;
    width: 100%;
    display: block;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .change-password input:focus, .change-password select:focus {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
  }
  
  .change-password label {
    font-weight: bold;
    display: block;
    margin-bottom: .5rem;
  }

  .change-password .error, .change-password .success {
        font-size: 1rem;
        text-align: left;
        margin: 1rem 0;
        font-weight: 400;
    }

  .change-password .input-feedback, .change-password .error {
      color: red;
  }

  .change-password button {
    padding: 8px 15px;
    background: white;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 7px;
    font-weight: 500;
  }

  .change-password button:hover {
    background: #310D31;
    color: white;
  }

   
.userIcon {
    border: 1px solid #1B998B; 
    padding: 1px 4px;
    border-radius: 50%;
    /* margin: 10px; */
}



#header {
  background: rgb(239, 243, 244);
}

#header > th {
  background: rgb(239, 243, 244);
  position: -webkit-sticky;
  position: sticky;
  top: -2px;
  font-size: 13px;
}

.rowHover tr:nth-child(even) {
  background-color: transparent;
}

.rowHover tr > td {
  font-size: 13px;
}

#header {
  background: rgb(239, 243, 244);
}

#header > th {
  background: rgb(239, 243, 244);
  position: -webkit-sticky;
  position: sticky;
  top: -2px;
  font-size: 13px;
}

.rowHover tr:nth-child(even) {
  background-color: transparent;
}

.rowHover tr > td {
  font-size: 13px;
}

.ticket__header {
  background: #ddf4f2;
  padding: 20px;
  border-radius: 7px;
  /* box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1); */
}

.header__topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__topbar span {
  background: #ffa41d;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 7px;
}

@media (max-width: 768px) {
  .header__topbar {
    flex-direction: column;
  }

  .header__topbar span {
    font-size: 12px;
    padding: 2px 5px;
  }

  .header__topbar > div {
    margin-bottom: 10px;
  }
}

.ticket__response__body,
.comments__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
     -moz-column-gap: 40px;
          column-gap: 40px;
  grid-row-gap: 20px;
  row-gap: 20px;
  margin-top: 20px;
}

.ticket__response__body .main__response {
  grid-column: 1 / 3;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.comments__container__body {
  grid-column: 1 / 3;
}

.main__responses__inner {
  padding: 20px;
}

.main__responses__inner .single__question {
  margin-bottom: 20px;
}

.options__container {
  display: flex;
  align-items: center;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
  pointer-events: none;
  flex-wrap: wrap;
}

.ticket__response__body .linked__response {
  grid-column: 3 / 4;
}

.linked__response {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  min-height: 0;
  overflow-y: auto;
}

.linked__response__inner {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.linked__response__inner > p {
  margin: 0;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.linked__response__item {
  background: #fff;
  border: 2px solid #1b998b;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}

.linked__response__item p {
  margin: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 8px;
}

.linked__response__item a {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 8px;
  display: inline-block;
  color: #0066ff;
  border-bottom: 1px solid #0066ff;
}

.linked__response__item p span {
  font-weight: 600;
}

.linked__response__item p:first-child {
  display: inline-block;
  background: #ddf4f2;
  padding: 2px 8px;
  border-radius: 5px;
  font-weight: 600;
}

.comments__body__inner {
  padding-left: 20px;
  width: 100%;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.comments__container__body h5 {
  padding: 10px 20px 20px 20px;
  margin: 0;
}

.comments__container__body .user__avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #1b998b;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .comments__body__inner {
    grid-gap: 10px;
    gap: 10px;
    padding-left: 10px;
  }

  .comments__container__body h5 {
    padding: 10px;
  }

  .comments__container__body .user__avatar {
    width: 32px;
    height: 32px;
    font-size: 14px;
  }
}

.comments__container__body textarea {
  width: 100%;
  padding: 10px;
  resize: none;
  border: none;
  outline: none;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .ticket__response__body {
    grid-template-columns: 1fr;
  }

  .ticket__response__body .main__response {
    grid-column: 1 / 2;
  }

  .ticket__response__body .linked__response {
    grid-column: 1 / 2;
  }

  .comments__container {
    grid-template-columns: 1fr;
  }

  .comments__container__body {
    grid-column: 1 / 2;
  }
}

/* .pro {
    position: relative;
}

.pro:after {
    position: absolute;
    content: "Pro";
    width: 35px;
    height: 25px;
    background: gold;
    border-radius: 50px;
    color: red;
    line-height: 25px;
    top: -25px;
    left: 75px;
    font-size: .9em;
} */


.priceCompareRowHover{
    color: #f3f9ff;
} 

.priceCompareRowHover tr:nth-child(even) { background-color: #f3f9ff; } 

.priceCompareRowHover tr > td { font-size: 13px; } 


.tableRowHover tr:nth-child(even) { background-color: transparent; } 



.blogImg > div { text-align: center; } 
.blogImg > div > img { max-height: 250px !important; } 


* { margin: 0; padding: 0; outline: 0; box-sizing: border-box; font-family: Roboto; } 

.wrapper { width: 100%; } 

.elementReset { 
    z-index: 1000; display: flex; flex-direction: column; 
    justify-content: center; align-items: center; height: 100vh; 
    background: url(/static/media/background5.20ae1d24.png); 
    background-repeat: no-repeat; background-size: cover; background-origin: border-box; 
} 

.logo { margin: 0px 0px 10px 0px; } 

.fieldBox { 
    background: white; width: 370px; padding: 40px; border-radius: 15px;
    box-shadow: 0px 0px 5px 0px rgb(214, 214, 214);
} 

.boxHeader { margin-bottom: 15px; font-size: 17px; color: rgb(52, 58, 64); text-align: left; } 

.resetPassword::-webkit-input-placeholder {
  font-size: 14px;
} 

.resetPassword::-moz-placeholder {
  font-size: 14px;
} 

.resetPassword::-ms-input-placeholder {
  font-size: 14px;
} 

.resetPassword::placeholder {
  font-size: 14px;
}


@media screen and (max-width: 425px) { 
  .fieldBox { width: 370px; padding: 40px; margin-top: 0px } 
  /* .logo { margin: -20px 0px 10px 0px; }  */
} 

@media screen and (max-width: 375px) { 
  .fieldBox { width: 330px; padding: 40px; margin-top: 0px } 
  /* .logo { margin: -20px 0px 10px 0px; }  */
} 


@media screen and (max-width: 325px) { 
  .fieldBox { width: 310px; padding: 40px; margin-top: 0px } 
  /* .logo { margin: -20px 0px 10px 0px; }  */
} 







* { margin: 0; padding: 0; outline: 0; box-sizing: border-box; font-family: Roboto; }

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/static/media/background5.20ae1d24.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: border-box;
}

.success, .fail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success h3, .fail h3 {
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 1rem;
}

