.ReactDatePicker .react-datepicker {
    font-size: .8rem;
    border: 1px solid #E0E0E0;
}

.ReactDatePicker .react-datepicker__header {
    border-bottom: none;
    background-color: #F5F5F5;
}

.ReactDatePicker .react-datepicker__input-container input {
    padding: 6px 4px;
    border-radius: 2px;
    border: 1px solid #BDBDBD;
    font-size: 11px;
    width: 68px;
}

.ReactDatePicker .react-datepicker__day {
    background-color: #EEE;
    border-radius: 2px;
}

.ReactDatePicker .react-datepicker__day--selected {
    background-color: rgb(15, 209, 144); 
}

.ReactDatePicker .react-datepicker__week {
    border-bottom: 1px solid #E0E0E0;
}

.ReactDatePicker .react-datepicker__week:last-child {
    border: none;
}

.ReactDatePicker .react-datepicker__year-select,
.ReactDatePicker .react-datepicker__month-select {
    border:none;
    padding:2px;
    border-radius: 2px;
    color: #424242;
    font-weight: 700;
}