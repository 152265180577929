.light {
    height: 35px;
    width: 35px;
    display: inline-block;
    border-radius: 
    50%;
    margin: 0 4px;
}

.light-green {
    background-color: rgba(0, 230, 118, .8);
    /* background-color: rgba(42, 185, 123, 0.5); */
    /* -webkit-box-shadow: 3px 2px 32px 7px rgba(0, 230, 118, 1);
    -moz-box-shadow: 3px 2px 32px 7px rgba(0, 230, 118, 1);
    box-shadow: 3px 2px 32px 7px rgba(0, 230, 118, 1); */
}

.light-yellow {
    background-color: rgba(255, 235, 59, .8);
    /* -webkit-box-shadow: 3px 2px 32px 7px rgba(255, 235, 59, 1);
    -moz-box-shadow: 3px 2px 32px 7px rgba(255, 235, 59, 1);
    box-shadow: 3px 2px 32px 7px rgba(255, 235, 59, 1); */
}

.light-red {
    background-color: rgba(255, 23, 68, .8);
    /* background-color: rgba(165, 1, 34, 0.8); */
    /* -webkit-box-shadow: 3px 2px 32px 7px rgba(255, 23, 68, 1);
    -moz-box-shadow: 3px 2px 32px 7px rgba(255, 23, 68, 1);
    box-shadow: 3px 2px 32px 7px rgba(255, 23, 68, 1); */
}

.green-active {
    background-color: rgba(0, 230, 118, 1);
    -webkit-box-shadow: 0px 0px 11px 6px rgba(0, 230, 118, 1);
    -moz-box-shadow: 0px 0px 11px 6px rgba(0, 230, 118, 1);
    box-shadow: 0px 0px 11px 6px rgba(0, 230, 118, 1);
}

.yellow-active {
    background-color: rgba(255, 235, 59, 1);
    -webkit-box-shadow: 0px 0px 11px 6px rgba(255, 235, 59, 1);
    -moz-box-shadow: 0px 0px 11px 6px rgba(255, 235, 59, 1);
    box-shadow: 0px 0px 11px 6px rgba(255, 235, 59, 1);
}

.red-active {
    background-color: rgba(255, 23, 68, 1);
    -webkit-box-shadow: 0px 0px 11px 6px rgba(255, 23, 68, 1);
    -moz-box-shadow: 0px 0px 11px 6px rgba(255, 23, 68, 1);
    box-shadow: 0px 0px 11px 6px rgba(255, 23, 68, 1);
}



