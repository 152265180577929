.mobileCollapse { 
    padding-top: 1rem;
    /* padding-bottom: 1rem; */
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: -15px;
    margin-left: -15px; 
} 


.dropdown-toggle::after {
    /* position: relative;  */
    top: 3px;
}


.secondary-menu-link:hover {
    background: transparent!important;
}
