
/*FOOTER*/

footer {
    /* background: #1e7a92 !important; */
    background: #1B998B;
    color: white;
    margin-top:100px;
}
  
.footer-li > li > a { color: #fff; font-size: 14px; }
  
footer a:hover { color: #FA944B; text-decoration: none; }
  
.copy { font-size: 12px; padding: 10px; border-top: 1px solid #FFFFFF; }
  
.footer-middle { padding-top: 2em; color: white; }

ul li a { color: white; font-size: 18px; }
ul li a:hover { color: white; }

