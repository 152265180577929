.carousel-indicators li {
    background-color: #1B998B !important;
}

.carousel-caption h3 {
    color: #1B998B; 
    font-size: 32px;
    font-weight: 500;
    text-align: left;
    position: relative; 
    top: -250px; 
    left: -100px; 
} 

.carousel-caption p {
    width: 75%; 
    color: rgb(32, 32, 32); 
    font-size: 18px; 
    font-weight: 500; 
    text-align: left; 
    position: relative; 
    top: -230px; 
    left: -100px; 
} 

.sliderBtn { 
    background-color: #1B998B !important; border-color: #1B998B !important; 
    color: white !important; font-weight: 500; border-radius: 0% !important; position: relative !important; 
    bottom: 210px; left: 580px; margin: 5px; 
} 

.carousel-item .d-none { display: block !important; } 

.carouselTitleOnly { display: none !important; } 


@media only screen and (max-width: 1024px) { 
    .carousel-caption h3 { top: -130px; left: -100px; font-size: 28px; } 
    .carousel-caption p { top: -130px; left: -100px; font-size: 18px; } 
    .sliderBtn { bottom: 130px; left: 400px; }
} 


@media only screen and (max-width: 768px) { 
    .carousel-caption h3 { top: -50px; left: -80px; font-size: 22px; } 
    .carousel-caption p { top: -40px; left: -80px; font-size: 16px; } 
    .sliderBtn { bottom: 50px; left: 290px; }
} 


@media only screen and (max-width: 750px) { 
    .hideCarousel { display: none; } 
    .carouselTitleOnly { display: block !important; text-align: center; margin: 25px 0px;  } 
    .carouselTitleOnly > h6 { color: #1B998B; font-size: 25px !important; font-weight: 500;  } 
} 


/* @media only screen and (max-width: 425px) { 
    .carousel-caption h3 { top: -70px; left: -40px; font-size: 13px; width: 80% !important; } 
    .carousel-caption p { display: none !important; } 
    .sliderBtn { bottom: 60px; left: 20px; font-size: 11px !important; padding: 3px !important; border-radius: 0% !important; }
} 




@media only screen and (max-width: 320px) { 
    .carousel-caption h3 { top: -40px; left: -30px; font-size: 13px; width: 90% !important; } 
    .carousel-caption p { display: none !important; } 
    .sliderBtn { bottom: 35px; left: 15px; font-size: 11px !important; padding: 3px !important; border-radius: 0% !important; }
}  */

