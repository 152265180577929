.CircularProgress h6.title {
    color:#424242;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
}

.flex-wrapper {
    display: flex;
    flex-flow: row nowrap;
}

.CircularProgress .single-chart {
    width: 33%;
    justify-content: space-around;
}

.CircularProgress .circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 250px;
}

.CircularProgress .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
}

.CircularProgress .circle {
    fill: none;
    stroke-width: 3.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

.CircularProgress .circular-chart.high .circle {
    stroke: rgba(27, 232, 143, .7);
}

.CircularProgress .circular-chart.medium .circle {
    stroke: rgba(238, 203, 73, .8);
}

.CircularProgress .circular-chart.low .circle {
    stroke: rgba(255, 178, 56, .8);
}

.CircularProgress .circular-chart.very-low .circle {
    stroke: rgba(242, 73, 76, .8);
}

.CircularProgress .percentage {
    fill: #616161;
    font-family: sans-serif;
    font-size: 5px;
    font-weight: 700;
    text-anchor: middle;
}