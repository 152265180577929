.Line .title {
    font-size: 14px;
    font-weight: 700;
    color: #424242;
}


.Line .Line-tooltip {
    background-color: #FFF;
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 700;
    width: 100px !important;
    word-wrap: break-word;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color:#424242;
}

.Line .Line-tooltip > h6 {
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    border-bottom: 1px solid #BDBDBD;
    color: #212121;
}