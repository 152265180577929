.CardSummary {
    box-shadow: 0px 1px 10px rgba(84, 110, 122, 0.16), 0 2px 3px rgba(84, 110, 122, 0.23);
    border: none;
    height: 100%;
}

.CardSummary .summary-title {
    font-size: 15px;
    margin-bottom: 5px;
    color: #071E3D;
    font-weight: 500;
    
}


.CardSummary .summary-value {
    font-weight: 300;
    font-size: 24px;
    color: #1B998B;
}